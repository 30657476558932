import { FaLocationDot } from "react-icons/fa6";
import { BiCurrentLocation } from "react-icons/bi";
import GoogleMap from "../GoogleMap";
import { useEffect, useState } from "react";
import { useGeolocation } from "../../hooks/useGeolocation";
import { useDispatch } from "react-redux";
import { setCoordinates, setAddressDetails } from "../../features/mapSlice";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const LocationPage = ({ handlePageChange }) => {
  const google = window.google;
  const { locationInfo, getGeolocation, loading } = useGeolocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const dispatch = useDispatch();

  useEffect(() => {
    if (locationInfo) {
      dispatch(
        setCoordinates({
          lat: locationInfo.latitude,
          lng: locationInfo.longitude,
        })
      );
      setMapLoading(true); // Start map loading
      // setIsMapVisible(true);
      fetchAddressDetails(locationInfo.latitude, locationInfo.longitude);
    }
  }, [locationInfo]);

  const handleUseLocationClick = () => {
    setMapLoading(true);
    getGeolocation();
  };

  const fetchAddressDetails = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC_kEWY80qIUDJUCZLSqCBktnB28Nyn6xw`
      );
      const data = await response.json();

      if (data.results[0]) {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let state = "";
        let city = "";
        let pincode = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            pincode = component.long_name;
          }
        });
        dispatch(
          setAddressDetails({
            address: formattedAddress || "",
            city,
            state,
            pinCode: pincode,
          })
        );
      }
      setIsMapVisible(true);
    } catch (error) {
      console.error("Error fetching address details:", error);
    } finally {
      setMapLoading(false); // Stop map loading
    }
  };

  const handlePlaceSearch = (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: query }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSearchResults(predictions || []);
      } else {
        console.error("Place search failed:", status);
        setSearchResults([]);
      }
    });
  };

  const handleSearchResultClick = (placeId) => {
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place.geometry
      ) {
        const addressComponents = place.address_components;

        const getAddressComponent = (type) => {
          const component = addressComponents.find((comp) =>
            comp.types.includes(type)
          );
          return component ? component.long_name : null;
        };

        const city = getAddressComponent("locality");
        const state = getAddressComponent("administrative_area_level_1");
        const pinCode = getAddressComponent("postal_code");

        let cleanedAddress = place.formatted_address;
        if (city) cleanedAddress = cleanedAddress.replace(city, "").trim();
        if (state) cleanedAddress = cleanedAddress.replace(state, "").trim();
        if (pinCode)
          cleanedAddress = cleanedAddress.replace(pinCode, "").trim();
        cleanedAddress = cleanedAddress
          .replace(/,\s*$/, "")
          .replace(/\s{2,}/g, " ");

        dispatch(
          setCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          })
        );
        dispatch(
          setAddressDetails({
            address: cleanedAddress || "",
            city,
            state,
            pinCode,
          })
        );
        setSearchQuery(place.formatted_address || "");
        setSearchResults([]);
        setIsMapVisible(true);
      } else {
        console.error("Failed to fetch place details:", status);
      }
    });
  };

  return (
    <div className="overflow-scroll">
      {isAuthenticated === "true" && (
        <div className="mt-5 mb-3">
          <button
            onClick={() => handlePageChange("addresses")}
            className="px-5 mb-3 font-semibold w-full"
          >
            <div className="px-5 flex justify-between items-center w-full bg-formisGrey/70 py-2 rounded-[10px]">
              View Saved Addresses{" "}
              <MdOutlineKeyboardArrowRight className="text-2xl" />
            </div>
          </button>

          <p className="px-5">OR</p>
        </div>
      )}
      <div>
        <div className="flex items-center gap-3 px-5">
          {isAuthenticated !== "true" ? (
            <p className="text-lg font-semibold">Change delivery location</p>
          ) : (
            <p className="text-lg font-semibold">Add New Address</p>
          )}
        </div>
      </div>

      <div className="my-5">
        <div className="mx-5 bg-formisGrey/70 flex flex-col gap-4 rounded-[10px] mb-5">
          <div className="flex items-center py-2 px-4 gap-4">
            <FaLocationDot className="text-xl" />
            <input
              className="bg-transparent outline-none w-full"
              placeholder="Search address"
              onChange={(e) => {
                setSearchQuery(e.target.value);
                handlePlaceSearch(e.target.value);
              }}
              value={searchQuery}
            />
          </div>
        </div>

        <button
          onClick={handleUseLocationClick}
          className="px-5 flex gap-3 items-center"
        >
          <div className="bg-formisGrey/70 rounded-[5px] p-3">
            <BiCurrentLocation className="text-xl" />
          </div>
          <p className="font-semibold border-b border-formisBlack">
            Use your current location
          </p>
        </button>
      </div>

      {searchResults.length > 0 && (
        <div className="px-5">
          {searchResults.map((result) => (
            <div
              key={result.place_id}
              onClick={() => handleSearchResultClick(result.place_id)}
              className="py-2 hover:bg-formisGrey/20 flex items-center gap-3 rounded-[10px] cursor-pointer"
            >
              <div className="bg-formisGrey/40 p-3 rounded-[10px]">
                <FaLocationDot className="text-xl" />
              </div>
              <p>{result.description}</p>
            </div>
          ))}
        </div>
      )}

      {isMapVisible && !loading && !mapLoading && (
        <div>
          <div className="mx-5 mb-12">
            <GoogleMap />
          </div>
          <div className="flex items-center justify-center sticky md:fixed bottom-0 left-0 bg-formisGrey w-full py-5">
            <button
              onClick={() => handlePageChange("address")}
              className="bg-formisBlack text-formisPeach px-10 py-3 rounded-full"
            >
              Confirm Location and Continue
            </button>
          </div>
        </div>
      )}
      {loading ||
        (mapLoading && (
          <div className="w-full flex items-center justify-center">
            <div className="loader3"></div>
          </div>
        ))}
    </div>
  );
};

export default LocationPage;
