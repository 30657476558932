import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductCard from "../components/Product Page Components/ProductCard";
import ProductFeatures from "../components/Product Page Components/ProductFeatures";
import ProductFAQ from "../components/Product Page Components/ProductFAQ";
import Services from "../components/Services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Product = () => {
  const { slug } = useParams();
  const [productData, setProductData] = useState();

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/${slug}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductData(data);
        console.log("Single Product Data:", data);
      }
    } catch {}
  };

  useEffect(()=>{
    fetchProducts();
  }, [])
  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>

      {productData && <div className="bg-formisWhite px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20">
        <ProductCard productData={productData} />
        <ProductFeatures productData={productData} />
        {productData.questions!=="NA" && <ProductFAQ productData={productData} />}
      </div>}

      <Services />
      <Footer />
    </div>
  );
};

export default Product;
