import { useEffect, useRef, useState } from "react";
import useRefresh from "../hooks/useRefresh";
import { IoCloseOutline } from "react-icons/io5";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { profileApi } from "../api";

const Profile = ({ isProfileOpen, toggleProfile }) => {
  const [profileData, setProfileData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { refreshAccessToken } = useRefresh();
  const isEffectCalled = useRef(false);

  const fetchProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(profileApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setProfileData(data);
      } else if (response.status === 401) {
        const success = await refreshAccessToken();
        if (success) {
          fetchProfile();
        }
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const saveProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch("https://kitsa.co.in/api/v1/profile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          first_name: profileData.first_name,
          last_name: profileData.last_name,
        }),
      });
      if (response.ok) {
        setIsEditing(false);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    if (isEffectCalled.current === false) {
      fetchProfile();
    }
    return () => (isEffectCalled.current = true);
  }, []);

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isProfileOpen ? "bg-formisBlack/30 z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={toggleProfile}
      />
      <div
        id="search-modal-main"
        className={`fixed md2:top-0 md2:right-0 bg-white z-[31] rounded-t-[20px] md2:rounded-t-none md2:rounded-tl-[20px] md2:rounded-l-[20px] w-screen h-[92dvh] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:pt-10 md2:h-screen
               transition-transform duration-500 flex flex-col ${
                 isProfileOpen
                   ? "slide-up md2:translate-x-0"
                   : "slide-down md2:translate-x-full"
               }`}
      >
        <div className="h-[15%]">
          <div className="md2:hidden mx-auto w-16 h-1 bg-formisGrey rounded-full my-4"></div>
          <div className="flex justify-between items-center pb-7 md2:pb-10 px-5 md2:px-12">
            <h3 className="text-[26px] md2:text-3xl font-bold">Profile</h3>
            <div
              onClick={toggleProfile}
              className="p-2 md2:p-3 rounded-full border border-formisGrey text-formisBlack hover:text-formisPeach text-2xl cursor-pointer hover:bg-formisBlack transition-all hover:rotate-90 duration-500"
            >
              <IoCloseOutline />
            </div>
          </div>
          <hr className="border-t border-formisGrey" />
        </div>

        <div className="px-5">
          {profileData ? (
            <div>
              <div className="cursor-pointer flex items-center justify-between gap-3 mb-5">
                <div className="flex items-center gap-2">
                  <FaUserEdit />
                  <button onClick={handleEditToggle}>
                    {isEditing ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>
              <div>
                <p>First Name</p>
                <input
                  className="address-input"
                  readOnly={!isEditing}
                  name="first_name"
                  value={profileData.first_name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <p>Last Name</p>
                <input
                  className="address-input"
                  readOnly={!isEditing}
                  name="last_name"
                  value={profileData.last_name}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <p>Mobile Number</p>
                <input
                  className="address-input"
                  readOnly
                  value={profileData.mobile_number}
                />
              </div>
              {isEditing && (
                <button
                  onClick={saveProfile}
                  className="mt-4 px-4 py-2 bg-formisBlack text-formisPeach rounded-full"
                >
                  Save
                </button>
              )}
            </div>
          ) : (
            <div className="w-full text-center">
              <Link to="/login" onClick={toggleProfile} className="bg-formisBlack text-formisPeach px-4 py-2 rounded-full">Login to view profile</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
