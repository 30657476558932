import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "../../../features/cartSlice";
import imageNA from "../../../assets/Image-NA.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";

const CartProduct = ({ item, userCart }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find((cartItem) => cartItem.sku_id === item.sku_id)
  );

  const handleDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ sku_id: item.sku_id }));
    }
  };

  return (
    <div key={item.id} className="flex gap-3 items-center mb-3 px-5">
      <img
        src={
          item.image_url !== "NA" &&
          item.image_url !==
            "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
            ? item.image_url
            : imageNA
        }
        alt={item.name}
        className="border-2 size-20 aspect-square rounded-[5px]"
      />

      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-[500] text-sm md2:text-base leading-none">
            {item?.name.length > 42
              ? item.name.slice(0, 42) + "..."
              : item.name}
          </p>
          <span className="text-xs capitalize">{item.packaging}</span>
        </div>

        <div className="flex flex-col items-end">
          <div className="bg-formisBlack/10 rounded-[10px] flex gap-1 py-[2px] items-center mb-1">
            <div className="p-1">
              {cartItem.quantity === 1 ? (
                <RiDeleteBin6Line
                  onClick={handleDecrement}
                  className="text-formisBlack/50"
                />
              ) : (
                <IoIosArrowDown
                  className="rotate-90"
                  onClick={handleDecrement}
                />
              )}
            </div>
            <input
              type="number"
              min="1"
              value={cartItem?.quantity || 0}
              readOnly
              className="max-w-4 border bg-transparent rounded-[5px] text-center outline-none"
            />
            <div className="p-1">
              <IoIosArrowUp
                className="rotate-90"
                onClick={() =>
                  dispatch(incrementQuantity({ sku_id: item.sku_id }))
                }
              />
            </div>
          </div>
          {cartItem.quantity !== 1 && (
            <button
              onClick={() => dispatch(removeFromCart(item))}
              className="text-xs border-b border-formisBlack/50 mb-1"
            >
              Remove
            </button>
          )}

          <div className="flex text-xs gap-1">
            <s>₹{item.mrp.toFixed(2)}</s>
            <p className="font-[600]">₹{item.final_price.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
