import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import { categories } from "../categoriesData";
import { categoryProductSearch } from "../api";
import imageNA from "../assets/Image-NA.png";
import { MdNavigateNext } from "react-icons/md";

const AllProducts = () => {
  const { categoryName } = useParams();
  const [searchInput, setSearchInput] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // const debouncedSearchInput = useDebounce(searchInput, 1000);

  // const fetchSearchResults = async (searchQuery) => {
  //   if (!searchQuery) {
  //     setSearchResults([]);
  //     setLoading(false);
  //     return;
  //   }
  //   setLoading(false);
  //   try {
  //     const response = await fetch(
  //       categoryProductSearch + `${searchQuery}&category=${categoryName}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     const data = await response.json();

  //     if (response.ok) {
  //       setSearchResults(data);
  //       console.log("Category Search: ", data);
  //     } else {
  //       setSearchResults([]);
  //     }
  //   } catch {
  //     setSearchResults([]);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (debouncedSearchInput) {
  //     fetchSearchResults(debouncedSearchInput);
  //   }
  // }, [debouncedSearchInput]);

  // const handleInputChange = (e) => {
  //   setSearchInput(e.target.value);
  // };

  // useEffect(() => {
  //   setLoading(true);
  //   if (!searchInput) {
  //     setSearchResults([]);
  //   }
  // }, [searchInput]);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/category/${categoryName}/?page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductsData(data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 300);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 300);
  };

  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <div
          style={{
            backgroundImage: `url(https://concept-theme-tech.myshopify.com/cdn/shop/collections/all-products.webp?v=1709198878&width=600)`,
          }}
          className="relative h-[30vh] lg:h-[40vh] bg-no-repeat bg-cover bg-[50%] bg-formisBlack rounded-t-[20px]"
        >
          <div className="h-full w-full absolute top-0 left-0 bg-formisBlack/70" />

          <Header transparent={true} />

          {/* <div className="relative top-5 z-10">
            <div className="px-4 flex max-w-[70%] lg:max-w-[30%] mx-auto items-center text-formisBlack bg-formisWhite/50 rounded-[10px] backdrop-blur-md">
              <svg
                className="w-7 cursor-pointer"
                viewBox="0 0 20 20"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.4007 17.4998L15.3707 14.4698M15.3707 14.4698C16.7279 13.1126 17.5674 11.2376 17.5674 9.1665C17.5674 5.02437 14.2095 1.6665 10.0674 1.6665C5.92525 1.6665 2.56738 5.02437 2.56738 9.1665C2.56738 13.3086 5.92525 16.6665 10.0674 16.6665C12.1385 16.6665 14.0135 15.827 15.3707 14.4698Z"
                ></path>
              </svg>
              <input
                placeholder={`Search in ${categoryName}`}
                className="bg-transparent font-[500] md2:text-2xl pl-2 md:pl-5 py-2 w-full placeholder:text-formisBlack/60 outline-none"
                onChange={handleInputChange}
              />
            </div>
          </div> */}

          <div className="relative flex items-end h-[calc(100%-7rem)] md:h-[calc(100%-8rem)] lg:h-[calc(100%-10rem)] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 text-formisPeach">
            <div>
              <div className="flex items-center gap-4 md:gap-6 text-[13px] lg:text-[14px] xl:text-[16px] leading-none">
                <svg
                  className="w-4"
                  viewBox="0 0 20 21"
                  stroke="currentColor"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.333 14.667v-3.713c0-1.355 0-2.033-.165-2.66a5 5 0 0 0-.818-1.702c-.387-.521-.916-.945-1.974-1.791l-.378-.303h0c-1.784-1.427-2.676-2.14-3.665-2.414a5 5 0 0 0-2.666 0c-.99.274-1.881.987-3.665 2.414h0l-.378.303c-1.058.846-1.587 1.27-1.974 1.79a5 5 0 0 0-.818 1.703c-.165.627-.165 1.305-.165 2.66v3.713a4.167 4.167 0 0 0 4.166 4.166c.92 0 1.667-.746 1.667-1.666v-3.334a2.5 2.5 0 0 1 5 0v3.334c0 .92.746 1.666 1.667 1.666a4.167 4.167 0 0 0 4.166-4.166Z"
                  ></path>
                </svg>
                <span>/</span>
                <p>Categories</p>
                <span>/</span>
                <p>{categoryName}</p>
              </div>
              <h2 className="title-md leading-none font-bold my-5">
                {categoryName}
              </h2>
            </div>
          </div>

          <div className="bg-formisWhite absolute bottom-0 left-0 w-full h-4 lg:h-10 rounded-t-[15px]"></div>
        </div>
      </div>

      {searchInput ? (
        <div className="bg-formisWhite rounded-t-[10px] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-10">
          {!loading ? (
            searchResults.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 bundle-card-gap">
                {searchResults.map((result, index) => (
                  <ProductCard
                    key={index}
                    image={result.image_url}
                    name={result.name}
                    mrp={result.mrp}
                    type={result.product_form}
                  />
                ))}
              </div>
            ) : (
              <div>not found</div>
            )
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 bundle-card-gap">
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 bundle-card-gap bg-formisWhite rounded-t-[10px] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-10">
          {productsData &&
            productsData.results.map((product, index) => (
              <ProductCard
                key={index}
                image={product.image_url}
                name={product.name}
                mrp={product.mrp}
                desc={product.packaging}
                slug={product.slug}
                salt={product.salt_composition}
                ingredients={product.ingredients}
                manufacturer={product.manufacturer}
              />
            ))}
        </div>
      )}

      <div className="flex gap-5 items-center justify-center pb-10">
        {currentPage !== 1 && (
          <button
            className="bg-formisPeach py-3 px-5 rounded-full flex items-center justify-center gap-2"
            onClick={handlePreviousClick}
          >
            <MdNavigateNext className="rotate-180 text-xl" />
            Previous
          </button>
        )}
        <button
          className="bg-formisPeach py-3 px-5 rounded-full flex items-center justify-center gap-2"
          onClick={handleNextClick}
        >
          Next <MdNavigateNext />
        </button>
      </div>

      <Services />
      <Footer />
    </div>
  );
};

export default AllProducts;

//------------

const ProductCard = ({
  name,
  desc,
  image,
  mrp,
  slug,
  salt,
  ingredients,
  manufacturer,
}) => {
  return (
    <div className="h-full flex flex-col justify-between rounded-[10px] border border-formisBlack text-formisBlack">
      <div className="relative">
        <Link to={`/product/${slug}`}>
          <img
            src={
              image !== "NA" &&
              image !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? image
                : imageNA
            }
            alt={name}
            className="rounded-t-[10px]"
          />
        </Link>
        <div className="bg-formisBlack text-formisPeach rounded-b-[10px] absolute -top-[1px] right-0">
          <p className="text-sm p-2">Learn More</p>
        </div>

        <div className="flex flex-col justify-between py-4 px-2 rounded-b-[10px]">
          <div>
            <span className="text-[12px] tracking-widest capitalize">
              {desc}
            </span>
            <p className="font-[600] lg:text-[19px] leading-tight my-2">
              {name}
            </p>
            <p className="text-sm font-[600] mb-2">MRP {mrp.toFixed(2)}/-</p>
            <p className="font-[Hind] text-xs md:text-sm font-[600] mb-2">
              Composition: {salt !== "NA" ? salt : ingredients}
            </p>
            <p className="font-[Hind] text-xs md:text-sm font-[600] mb-2">
              Manufacturer: {manufacturer}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TempProduct = () => {
  return (
    <div>
      <img
        src="https://concept-theme-tech.myshopify.com/cdn/shop/files/resonance-oasisflow-sand-01.webp?v=1708606611&width=1080"
        alt="Product"
        className="rounded-t-[10px]"
      />
      <div className="bg-[#fafafa] p-4 rounded-b-[10px]">
        <span className="text-[10px] tracking-widest">Tablet</span>
        <p className="font-[600] lg:text-[19px] leading-tight my-2">Product</p>
        <p className="text-sm font-[600]">Rs. 100</p>
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div>
      <div className="rounded-t-[10px] size-40 md:size-[11.25rem] md2:size-60 lg:size-64 xl:size-64 2xl:size-96 bg-formisGrey/50 mb-2" />
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
    </div>
  );
};
