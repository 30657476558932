import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
  name: "googleMapLocation",

  initialState: {
    coordinates: {
      lat: null,
      lng: null,
    },
    address: "",
    address2: "",
    city: "",
    state: "",
    pinCode: "",
    addressType: "",
    addressId: ""
  },

  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates.lat = action.payload.lat;
      state.coordinates.lng = action.payload.lng;
    },
    setAddressDetails: (state, action) => {
      state.address = action.payload.address;
      state.address2 = action.payload.address2;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.pinCode = action.payload.pinCode;
      state.addressType = action.payload.addressType;
      state.addressId = action.payload.addressId
    },
  },
});

export const { setCoordinates, setAddressDetails } = mapSlice.actions;
export default mapSlice.reducer;
