import Categories from "./Categories";
import { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { categoryProductsApi } from "../../api";

const MainPage = ({ handlePageChange, userCart, getCartDetails }) => {
  const categories = [
    "Skin, hair & oral care",
    "Stomach care",
    "Sexual wellness",
    "Feminine care",
    "Fitness supplements",
    "Vitamins & nutrition",
    "Ayurvedic supplements",
    "Nutritional drinks",
  ];
  const [results, setResults] = useState([]);

  const fetchCategory = async () => {
    try {
      const responses = await Promise.all(
        categories.map((category) =>
          fetch(
            categoryProductsApi + category
          ).then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch for parameter: ${category}`);
            }
            return response.json();
          })
        )
      );
      setResults(responses);
    } catch {}
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  // INPUT PLACEHOLDER ANIMATION
  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);
  const list = ["Search for Skin, Hair & Oral Care Products", "Search for Stomach Care Products", "Search for Sexual Wellness Products", "Search for Feminine Care Products", "Search for Fitness Supplements"];
  let i = 0;

  const textAnimation = () => {
    setCurrentText(list[i]);
    setVisible(true);
    setTimeout(() => setVisible(false), 1700);
    i = (i + 1) % list.length;
  };

  useEffect(() => {
    textAnimation();
    const interval = setInterval(() => {
      textAnimation();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-scroll pb-12">
      <div
        className="pt-7 md:pt-5 pb-5 px-5 sticky z-[100] -top-5 md:-top-2 bg-white left-0 cursor-text"
        onClick={() => handlePageChange("search")}
      >
        <div className="flex items-center gap-3 bg-formisGrey/50 rounded-[10px] px-5 py-3">
          <IoSearchOutline className="text-xl" />
          <div
            placeholder="Search for ..."
            className="flex items-center gap-2 bg-transparent font-[500] w-full placeholder:text-formisBlack/60 outline-none"
          >
            {/* <div>Search For</div> */}

            <div
              className={`text-sm md:text-base slider left-[-5px] ${
                visible
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 -translate-y-full"
              } transition-all`}
            >
              {currentText}
            </div>
          </div>
        </div>
      </div>

      <div>
        {results.map((res, index) => (
          <Categories
            key={index}
            result={res}
            handlePageChange={handlePageChange}
            userCart={userCart}
            name={categories[index]}
            getCartDetails={getCartDetails}
          />
        ))}
      </div>
    </div>
  );
};

export default MainPage;
