// import { useDispatch, useSelector } from "react-redux";
// import {
//   removeFromCart,
//   incrementQuantity,
//   decrementQuantity,
// } from "../../../features/cartSlice";
import { useState } from "react";
import imageNA from "../../../assets/Image-NA.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { addToCartApi, deleteFromCartApi } from "../../../api";

const CartProductApi = ({ item, getCartDetails, userCart }) => {
  const userCartItem = userCart?.find(
    (userItem) => userItem.product_id === item.product_id
  );

  const [productQuantity, setProductQuantity] = useState(userCartItem?.quantity || 1);
  const handleDecrement = () => {
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleIncrement = () => {
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    handleAddToCartApiCall(updatedQuantity);
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: item.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        await getCartDetails();
      } else {
      }
    } catch (error) {
      console.error("Failed to add to cart:", error);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_id: item.product_id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        getCartDetails();
      } else {
      }
    } catch (error) {
      console.error("Failed to delete:", error);
    }
  };

  return (
    <div key={item.id} className="flex gap-3 items-center mb-3 px-5">
      <img
        src={
          item.product_image !== "NA" &&
          item.product_image !==
            "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
            ? item.product_image
            : imageNA
        }
        alt=""
        className="border-2 size-20 aspect-square rounded-[5px]"
      />

      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-[500] text-sm md2:text-base leading-none">
            {item.product_name}
          </p>
          <span className="text-xs capitalize">{item.packaging}</span>
        </div>

        <div className="flex flex-col items-end">
          <div className="bg-formisBlack/10 rounded-[10px] flex gap-1 py-[2px] items-center mb-1">
            <div className="p-1">
              {userCartItem?.quantity === 1 ? (
                <RiDeleteBin6Line
                  className="text-formisBlack/50"
                  onClick={handleDecrement}
                />
              ) : (
                <IoIosArrowDown
                  className="rotate-90"
                  onClick={handleDecrement}
                />
              )}
            </div>
            <input
              type="number"
              min="1"
              readOnly
              value={productQuantity}
              className="max-w-4 border bg-transparent rounded-[5px] text-center outline-none"
            />
            <div className="p-1">
              <IoIosArrowUp className="rotate-90" onClick={handleIncrement} />
            </div>
          </div>
          {/* {item.quantity !== 1 && (
            <button className="text-xs border-b border-formisBlack/50 mb-1">
              Remove
            </button>
          )} */}

          <div className="flex text-xs gap-1">
            <s>₹{item.mrp.toFixed(2)}</s>
            <p className="font-[600]">
              ₹{item.selling_price_at_time.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProductApi;
