export const etaApi = "https://kitsa.co.in/api/v1/eta?"
export const searchAllApi = `https://kitsa.co.in/api/v1/medicine-search?search=`
export const categoryProductSearch = `https://kitsa.co.in/api/v1/medicine-search-by-category?search=`
export const categoryProductsApi = `https://kitsa.co.in/api/v1/medicines/category/`

export const advertisementsApi = "https://kitsa.co.in/api/v1/advertisements"
export const categoriesMainPageApi = "https://kitsa.co.in/api/v1/categories"
export const topbarInfoApi = "https://kitsa.co.in/api/v1/topbar-info"

export const sendOtpApi = "https://kitsa.co.in/api/v1/auth/send-otp"
export const verifyOtpApi = "https://kitsa.co.in/api/v1/auth/verify-otp"
export const addressesApi = "https://kitsa.co.in/api/v1/addresses"
export const profileApi = "https://kitsa.co.in/api/v1/profile"
export const addToCartApi = `https://kitsa.co.in/api/v1/cart/add`
export const deleteFromCartApi = `https://kitsa.co.in/api/v1/cart/remove`
export const getCartDetailsApi = `https://kitsa.co.in/api/v1/cart/detail`