import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressDetails } from "../../features/mapSlice";
import { addressesApi } from "../../api";
import useRefresh from "../../hooks/useRefresh";

const AddressPage = ({ handlePageChange }) => {
  const { address, city, state, pinCode, coordinates } = useSelector(
    (state) => state.googleMapLocation
  );
  const { refreshAccessToken } = useRefresh(handlePageChange);
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const [address1, setAddress1] = useState(address);
  const [address2, setAddress2] = useState("");
  const [cityInput, setCityInput] = useState(city);
  const [stateInput, setStateInput] = useState(state);
  const [pinInput, setPinInput] = useState(pinCode);
  const [addressTypeInput, setAddressTypeInput] = useState("");

  const [isSelected, setIsSelected] = useState();
  const [error, setError] = useState();

  const [message, setMessage] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAuthenticated === 'true') {
      handleAddAddress();
      return;
    }

    if (!addressTypeInput) {
      setError(true);
      return;
    }
    dispatch(
      setAddressDetails({
        address: address1,
        address2,
        city: cityInput,
        state: stateInput,
        pinCode: pinInput,
        addressType: addressTypeInput,
      })
    );

    handlePageChange("");
  };

  const handleAddAddress = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          name: addressTypeInput,
          address_line_1: address1,
          address2: address2 || null,
          city: cityInput,
          state: stateInput,
          latitude: parseFloat(coordinates.lat.toFixed(6)),
          longitude: parseFloat(coordinates.lng.toFixed(6)),
          postal_code: pinInput,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // console.log("Address Added", data);
        dispatch(
          setAddressDetails({
            address: address1,
            address2,
            city: cityInput,
            state: stateInput,
            pinCode: pinInput,
            addressType: addressTypeInput,
            addressId: data.id
          })
        );
        handlePageChange("")
      } else if (response.status === 401) {
        refreshAccessToken()
      } else {
        console.log("Error:", data);
        setMessage(data);
      }
    } catch (error) {
      console.log("Request Failed", error);
    }
  };

  const handleAddressTypeClick = (type, num) => {
    setIsSelected(num);
    if (type !== "Other") {
      setAddressTypeInput(type);
    } else setAddressTypeInput("")
    setError(false);
  };

  return (
    <div className="px-5 h-full">
      <div className="flex items-center gap-3 mb-5">
        <p className="text-lg font-semibold">Add Address</p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 items-center justify-center w-full"
      >
        <input
          type="text"
          placeholder="Address Line 1"
          value={address1}
          required
          onChange={(e) => setAddress1(e.target.value)}
          className="address-input"
        />
        <input
          type="text"
          placeholder="Address Line 2"
          value={address2}
          onChange={(e) => setAddress2(e.target.value)}
          className="address-input"
        />
        <input
          type="text"
          placeholder="City"
          value={cityInput}
          required
          onChange={(e) => setCityInput(e.target.value)}
          className="address-input"
        />
        <input
          type="text"
          placeholder="State"
          value={stateInput}
          required
          onChange={(e) => setStateInput(e.target.value)}
          className="address-input"
        />
        <input
          type="number"
          placeholder="Pin Code"
          value={pinInput}
          required
          onChange={(e) => setPinInput(e.target.value)}
          className="address-input"
        />

        <div className="flex items-center justify-start gap-4 w-full">
          <div
            onClick={() => handleAddressTypeClick("Work", 1)}
            className={`${
              isSelected === 1
                ? "bg-formisBlack text-formisPeach"
                : "bg-transparent text-formisBlack"
            } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
          >
            Work
          </div>
          <div
            onClick={() => handleAddressTypeClick("Home", 2)}
            className={`${
              isSelected === 2
                ? "bg-formisBlack text-formisPeach"
                : "bg-transparent text-formisBlack"
            } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
          >
            Home
          </div>
          <div
            onClick={() =>  handleAddressTypeClick("Other", 3)}
            className={`${
              isSelected === 3
                ? "bg-formisBlack text-formisPeach"
                : "bg-transparent text-formisBlack"
            } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
          >
            Other
          </div>

          <div className={`${isSelected === 3 ? "visible" : "hidden"}`}>
            <input
              required={isSelected === 3}
              placeholder="Save as"
              value={addressTypeInput} 
              onChange={(e) => setAddressTypeInput(e.target.value)}
              className="px-4 py-2 bg-formisGrey/20 outline-none w-full rounded-[10px]"
            />
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <p className="text-left w-full text-red-500 text-sm">
            Please select an address type.
          </p>
        )}

        {message && (
          <p className="text-left w-full text-red-500 text-sm">
            {message.name?.[0] || message.non_field_errors?.[0]}
          </p>
        )}

        <div className="flex items-center justify-center fixed bottom-0 left-0 bg-formisGrey w-full py-5">
          <button className="bg-formisBlack text-formisPeach px-10 py-3 rounded-full">
            Confirm Address
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddressPage;
