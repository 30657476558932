import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MobileFooter = ({ toggleMenu, toggleSearch, toggleCart, toggleProfile }) => {
  const { items } = useSelector((state) => state.cartItems);
  return (
    <footer className="text-formisBlack lg:hidden fixed z-[30] bottom-0 w-full left-0">
      {/* <div
        className="flex items-center gap-3 p-2 rounded-[10px] bg-formisWhite/80 backdrop-blur-sm mb-3 mx-[1.85rem] md:mx-10 md2:mx-14 border border-formisBlack/30"
        onClick={toggleSearch}
      >
        <svg
          className="w-6 cursor-pointer"
          viewBox="0 0 20 20"
          stroke="currentColor"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.4007 17.4998L15.3707 14.4698M15.3707 14.4698C16.7279 13.1126 17.5674 11.2376 17.5674 9.1665C17.5674 5.02437 14.2095 1.6665 10.0674 1.6665C5.92525 1.6665 2.56738 5.02437 2.56738 9.1665C2.56738 13.3086 5.92525 16.6665 10.0674 16.6665C12.1385 16.6665 14.0135 15.827 15.3707 14.4698Z"
          ></path>
        </svg>
        <div className="w-full bg-transparent cursor-text">Search</div>
      </div> */}
      
      <div className="flex justify-between bg-formisGrey rounded-t-[20px] px-[1.85rem] md:px-10 md2:px-14 py-4">
        <Link
          to="/"
          className="flex flex-col justify-center items-center text-[10px] gap-1"
        >
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 20 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.3337 14.1667V10.4538C18.3337 9.09868 18.3337 8.42113 18.1681 7.79394C18.006 7.17971 17.7284 6.602 17.35 6.09172C16.9637 5.57066 16.4346 5.1474 15.3764 4.30088L14.9979 3.99805L14.9979 3.99804C13.2143 2.57117 12.3225 1.85774 11.3335 1.58413C10.4611 1.34279 9.53956 1.34279 8.66717 1.58413C7.67815 1.85774 6.78636 2.57118 5.00277 3.99805L5.00276 3.99805L4.62423 4.30088C3.56607 5.1474 3.037 5.57066 2.65064 6.09172C2.27227 6.602 1.99461 7.17971 1.83251 7.79394C1.66699 8.42113 1.66699 9.09868 1.66699 10.4538V14.1667C1.66699 16.4679 3.53247 18.3333 5.83366 18.3333C6.75413 18.3333 7.50033 17.5871 7.50033 16.6667V13.3333C7.50033 11.9526 8.61961 10.8333 10.0003 10.8333C11.381 10.8333 12.5003 11.9526 12.5003 13.3333V16.6667C12.5003 17.5871 13.2465 18.3333 14.167 18.3333C16.4682 18.3333 18.3337 16.4679 18.3337 14.1667Z"
            ></path>
          </svg>
          <p>Home</p>
        </Link>

        <div
          className="flex flex-col justify-center items-center text-[10px] gap-1"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 21 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.2002 5H18.2002M3.2002 10H9.86686M3.2002 15H14.0335"
            ></path>
          </svg>
          <p>Menu</p>
        </div>

        <div
          className="flex flex-col justify-center items-center text-[10px] gap-1"
          onClick={toggleSearch}
        >
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 20 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.4007 17.4998L15.3707 14.4698M15.3707 14.4698C16.7279 13.1126 17.5674 11.2376 17.5674 9.1665C17.5674 5.02437 14.2095 1.6665 10.0674 1.6665C5.92525 1.6665 2.56738 5.02437 2.56738 9.1665C2.56738 13.3086 5.92525 16.6665 10.0674 16.6665C12.1385 16.6665 14.0135 15.827 15.3707 14.4698Z"
            ></path>
          </svg>
          <p>Search</p>
        </div>

        <div className="flex flex-col justify-center items-center text-[10px] gap-1">
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 21 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1.7666 4.8665C1.7666 3.7464 1.7666 3.18635 1.98459 2.75852C2.17634 2.3822 2.4823 2.07624 2.85862 1.88449C3.28644 1.6665 3.8465 1.6665 4.9666 1.6665H5.23327C6.35337 1.6665 6.91343 1.6665 7.34125 1.88449C7.71757 2.07624 8.02353 2.3822 8.21528 2.75852C8.43327 3.18635 8.43327 3.7464 8.43327 4.8665V5.13317C8.43327 6.25328 8.43327 6.81333 8.21528 7.24115C8.02353 7.61748 7.71757 7.92344 7.34125 8.11518C6.91343 8.33317 6.35337 8.33317 5.23327 8.33317H4.9666C3.8465 8.33317 3.28644 8.33317 2.85862 8.11518C2.4823 7.92344 2.17634 7.61748 1.98459 7.24115C1.7666 6.81333 1.7666 6.25328 1.7666 5.13317V4.8665Z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.7666 4.8665C11.7666 3.7464 11.7666 3.18635 11.9846 2.75852C12.1763 2.3822 12.4823 2.07624 12.8586 1.88449C13.2864 1.6665 13.8465 1.6665 14.9666 1.6665H15.2333C16.3534 1.6665 16.9134 1.6665 17.3413 1.88449C17.7176 2.07624 18.0235 2.3822 18.2153 2.75852C18.4333 3.18635 18.4333 3.7464 18.4333 4.8665V5.13317C18.4333 6.25328 18.4333 6.81333 18.2153 7.24115C18.0235 7.61748 17.7176 7.92344 17.3413 8.11518C16.9134 8.33317 16.3534 8.33317 15.2333 8.33317H14.9666C13.8465 8.33317 13.2864 8.33317 12.8586 8.11518C12.4823 7.92344 12.1763 7.61748 11.9846 7.24115C11.7666 6.81333 11.7666 6.25328 11.7666 5.13317V4.8665Z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1.7666 14.8665C1.7666 13.7464 1.7666 13.1863 1.98459 12.7585C2.17634 12.3822 2.4823 12.0762 2.85862 11.8845C3.28644 11.6665 3.8465 11.6665 4.9666 11.6665H5.23327C6.35337 11.6665 6.91343 11.6665 7.34125 11.8845C7.71757 12.0762 8.02353 12.3822 8.21528 12.7585C8.43327 13.1863 8.43327 13.7464 8.43327 14.8665V15.1332C8.43327 16.2533 8.43327 16.8133 8.21528 17.2412C8.02353 17.6175 7.71757 17.9234 7.34125 18.1152C6.91343 18.3332 6.35337 18.3332 5.23327 18.3332H4.9666C3.8465 18.3332 3.28644 18.3332 2.85862 18.1152C2.4823 17.9234 2.17634 17.6175 1.98459 17.2412C1.7666 16.8133 1.7666 16.2533 1.7666 15.1332V14.8665Z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.7666 14.8665C11.7666 13.7464 11.7666 13.1863 11.9846 12.7585C12.1763 12.3822 12.4823 12.0762 12.8586 11.8845C13.2864 11.6665 13.8465 11.6665 14.9666 11.6665H15.2333C16.3534 11.6665 16.9134 11.6665 17.3413 11.8845C17.7176 12.0762 18.0235 12.3822 18.2153 12.7585C18.4333 13.1863 18.4333 13.7464 18.4333 14.8665V15.1332C18.4333 16.2533 18.4333 16.8133 18.2153 17.2412C18.0235 17.6175 17.7176 17.9234 17.3413 18.1152C16.9134 18.3332 16.3534 18.3332 15.2333 18.3332H14.9666C13.8465 18.3332 13.2864 18.3332 12.8586 18.1152C12.4823 17.9234 12.1763 17.6175 11.9846 17.2412C11.7666 16.8133 11.7666 16.2533 11.7666 15.1332V14.8665Z"
            ></path>
          </svg>
          <p>Shop</p>
        </div>

        <div
          className="relative flex flex-col justify-center items-center text-[10px] gap-1"
          onClick={toggleCart}
        >
          {items.length !== 0 && (
            <span className="absolute -right-2 -top-3 text-[12px] font-semibold">
              {items.length}
            </span>
          )}
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 21 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M1.13281 0.833547L1.54948 0.833496V0.833496C2.78264 0.833526 3.86637 1.65101 4.20515 2.83672L4.3471 3.33355M4.3471 3.33355L5.63992 7.85843C6.11531 9.5223 6.35301 10.3542 6.83827 10.9717C7.26659 11.5168 7.82919 11.9412 8.47093 12.2033C9.19799 12.5002 10.0632 12.5002 11.7937 12.5002H12.8091C13.8588 12.5002 14.3837 12.5002 14.8433 12.39C15.9407 12.127 16.8759 11.4127 17.4184 10.4232C17.6456 10.0087 17.7837 9.50235 18.0599 8.4896V8.4896C18.3964 7.2559 18.5646 6.63905 18.5321 6.13859C18.4535 4.93171 17.6578 3.89005 16.5142 3.49667C16.0399 3.33355 15.4005 3.33355 14.1218 3.33355H4.3471ZM10.2995 16.6668C10.2995 17.5873 9.55329 18.3335 8.63281 18.3335C7.71234 18.3335 6.96615 17.5873 6.96615 16.6668C6.96615 15.7464 7.71234 15.0002 8.63281 15.0002C9.55329 15.0002 10.2995 15.7464 10.2995 16.6668ZM16.9661 16.6668C16.9661 17.5873 16.22 18.3335 15.2995 18.3335C14.379 18.3335 13.6328 17.5873 13.6328 16.6668C13.6328 15.7464 14.379 15.0002 15.2995 15.0002C16.22 15.0002 16.9661 15.7464 16.9661 16.6668Z"
            ></path>
          </svg>
          <p>Cart</p>
        </div>

        <div className="flex flex-col justify-center items-center text-[10px] gap-1" onClick={toggleProfile}>
          <svg
            className="w-6 cursor-pointer"
            viewBox="0 0 20 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="5.5" y="1.3335" width="9" height="9" rx="4.5"></rect>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 12.917C11.25 12.917 13.3333 13.1948 13.75 13.3337C14.1667 13.4725 16.8333 14.0003 17.5 15.0003C18.3333 16.2503 18.3333 16.667 18.3333 18.3337"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 12.917C8.75 12.917 6.66667 13.1948 6.25 13.3337C5.83333 13.4725 3.16667 14.0003 2.5 15.0003C1.66667 16.2503 1.66667 16.667 1.66667 18.3337"
            ></path>
          </svg>
          <p>Account</p>
        </div>
      </div>
    </footer>
  );
};

export default MobileFooter;
