import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

const Profile = ({ setExpandedMobileMenu }) => {
  return (
    <div>
      <button
        onClick={() => setExpandedMobileMenu(null)}
        className="flex items-center text-formisBlack/30"
      >
        <MdOutlineKeyboardArrowLeft className="text-3xl" />
        <p className="font-bold text-lg">Profile</p>
      </button>
      <hr className="border-t border-formisBlack/10 my-3" />
    </div>
  );
};

export default Profile;
