import React, { useEffect, useState } from "react";
import { addressesApi } from "../../api";
import useRefresh from "../../hooks/useRefresh";
import { FaHome } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { FaLocationDot } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { setAddressDetails, setCoordinates } from "../../features/mapSlice";

const SavedAddresses = ({ handlePageChange }) => {
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  const { refreshAccessToken } = useRefresh(handlePageChange);
  const [addresses, setAddresses] = useState();

  const fetchAddresses = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setAddresses(data.results);
      } else if (response.status === 401) {
        // refreshAccessToken();
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
  return (
    <div className="px-5 py-5 overflow-scroll">
      <p className="text-lg font-semibold mb-3">Your Saved Addresses</p>
      <div>
        {addresses?.map((address) => (
          <Address
            address={address}
            key={address.id}
            handlePageChange={handlePageChange}
          />
        ))}
      </div>
    </div>
  );
};

export default SavedAddresses;

const Address = ({ address, handlePageChange }) => {
  const dispatch = useDispatch();

  const handleSavedAddress = () => {
    dispatch(
      setAddressDetails({
        address: address.address_line_1,
        address2: address.address_line_2,
        city: address.city,
        state: address.state,
        pinCode: address.postal_code,
        addressType: address.name,
        addressId: address.id,
      })
    );

    dispatch(
      setCoordinates({
        lat: address.latitude,
        lng: address.longitude,
      })
    );
    handlePageChange("");
  };

  return (
    <div
      className="flex gap-5 bg-formisWhite p-3 rounded-[10px] mb-3"
      onClick={handleSavedAddress}
    >
      <div className="bg-formisGrey text-formisOrange h-fit p-3 text-xl rounded-[10px]">
        {address.name === "Home" ? (
          <FaHome />
        ) : address.name === "Work" ? (
          <ImOffice />
        ) : (
          <FaLocationDot />
        )}
      </div>

      <div className="text-xs">
        <p className="font-semibold text-base">{address.name}</p>
        <p>{address.address_line_1}</p>
        <p>{address.city}</p>
        <p>{address.state}</p>
      </div>
    </div>
  );
};
