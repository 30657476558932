// import Topbar from "../components/Topbar";
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { FaLocationDot } from "react-icons/fa6";
// import { BiCurrentLocation } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";
// import Services from "../components/Services";
// import Footer from "../components/Footer";
// import GoogleMap from "../components/GoogleMap";
// import { useEffect, useState } from "react";
// import Header from "../components/Header";
// import { useGeolocation } from "../hooks/useGeolocation";
// import { useDispatch } from "react-redux";
// import { setCoordinates } from "../features/mapSlice";

// const UserLocation = () => {
//   const google = window.google;
//   const { locationInfo, getGeolocation } = useGeolocation();
//   const navigate = useNavigate();
//   const [searchQuery, setSearchQuery] = useState("");
//   const [searchResults, setSearchResults] = useState([]);

//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (locationInfo) {
//       dispatch(
//         setCoordinates({
//           lat: locationInfo.latitude,
//           lng: locationInfo.longitude,
//         })
//       );
//     }
//   }, [locationInfo]);

//   const handlePlaceSearch = (query) => {
//     if (!query) {
//       setSearchResults([]);
//       return;
//     }

//     const service = new google.maps.places.AutocompleteService();
//     service.getPlacePredictions({ input: query }, (predictions, status) => {
//       if (status === google.maps.places.PlacesServiceStatus.OK) {
//         setSearchResults(predictions || []);
//       } else {
//         console.error("Place search failed:", status);
//         setSearchResults([]);
//       }
//     });
//   };

//   const handleSearchResultClick = (placeId) => {
//     const service = new google.maps.places.PlacesService(
//       document.createElement("div")
//     );
//     service.getDetails({ placeId }, (place, status) => {
//       if (
//         status === google.maps.places.PlacesServiceStatus.OK &&
//         place.geometry
//       ) {
//         dispatch(
//           setCoordinates({
//             lat: place.geometry.location.lat(),
//             lng: place.geometry.location.lng(),
//           })
//         );
//         setSearchQuery(place.formatted_address || "");
//         setSearchResults([]);
//       } else {
//         console.error("Failed to fetch place details:", status);
//       }
//     });
//   };

//   return (
//     <div>
//       <Topbar />
//       <div className="bg-formisBlack">
//         <Header />
//       </div>

//       <div className="bg-formisBlack">
//         <div className="bg-formisWhite py-6 flex items-center gap-3 px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12">
//           <button
//             onClick={() => navigate(-1)}
//             className="bg-formisGrey/70 rounded-full p-2"
//           >
//             <IoMdArrowRoundBack className="text-xl" />
//           </button>
//           <p className="title-sm font-semibold xl:font-bold">
//             Change delivery location
//           </p>
//         </div>
//       </div>

//       <div className="pb-5 mb-5">
//         <div className="mx-[1.85rem] md:mx-10 md2:mx-14 lg:mx-10 xl:mx-12 bg-formisGrey/70 flex flex-col gap-4 rounded-[10px] mb-5">
//           <div className="flex items-center py-2 px-4 gap-4">
//             <FaLocationDot className="text-xl" />
//             <input
//               className="bg-transparent outline-none w-full"
//               placeholder="Search address"
//               onChange={(e) => {
//                 setSearchQuery(e.target.value);
//                 handlePlaceSearch(e.target.value);
//               }}
//               value={searchQuery}
//             />
//           </div>
//         </div>

//         <button
//           onClick={getGeolocation}
//           className="px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12 flex gap-3 items-center"
//         >
//           <div className="bg-formisGrey/70 rounded-[5px] p-1">
//             <BiCurrentLocation className="text-3xl" />
//           </div>
//           <p className="font-semibold lg:text-xl border-b border-formisBlack">
//             Use your current location
//           </p>
//         </button>
//       </div>

//       {searchResults.length > 0 && (
//         <div className="px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12 mb-5">
//           {searchResults.map((result) => (
//             <div
//               key={result.place_id}
//               onClick={() => handleSearchResultClick(result.place_id)}
//               className="py-3 hover:bg-formisGrey/20 cursor-pointer"
//             >
//               {result.description}
//             </div>
//           ))}
//         </div>
//       )}

//       <div className="mx-[1.85rem] md:mx-10 md2:mx-14 lg:mx-10 xl:mx-12 mb-12">
//         <GoogleMap />
//       </div>

//       <Services />
//       <Footer />
//     </div>
//   );
// };

// export default UserLocation;

import { FaLocationDot } from "react-icons/fa6";
import { BiCurrentLocation } from "react-icons/bi";
import GoogleMap from "../components/GoogleMap";
import { useEffect, useState } from "react";
import { useGeolocation } from "../hooks/useGeolocation";
import { useDispatch } from "react-redux";
import { setCoordinates, setAddressDetails } from "../features/mapSlice";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const LocationPage = () => {
  const google = window.google;
  const { locationInfo, getGeolocation } = useGeolocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isMapVisible, setIsMapVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (locationInfo) {
      dispatch(
        setCoordinates({
          lat: locationInfo.latitude,
          lng: locationInfo.longitude,
        })
      );
      setIsMapVisible(true);
      fetchAddressDetails(locationInfo.latitude, locationInfo.longitude);
    }
  }, [locationInfo]);

  const handleUseLocationClick = () => {
    getGeolocation();
  };
  const fetchAddressDetails = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC_kEWY80qIUDJUCZLSqCBktnB28Nyn6xw`
      );
      const data = await response.json();

      if (data.results[0]) {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let state = "";
        let city = "";
        let pincode = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            pincode = component.long_name;
          }
        });
        dispatch(
          setAddressDetails({
            address: formattedAddress || "",
            city,
            state,
            pinCode: pincode,
          })
        );
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  const handlePlaceSearch = (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: query }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSearchResults(predictions || []);
      } else {
        console.error("Place search failed:", status);
        setSearchResults([]);
      }
    });
  };

  const handleSearchResultClick = (placeId) => {
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place.geometry
      ) {
        const addressComponents = place.address_components;

        // Helper function to get a specific address component by type
        const getAddressComponent = (type) => {
          const component = addressComponents.find((comp) =>
            comp.types.includes(type)
          );
          return component ? component.long_name : null;
        };

        const city = getAddressComponent("locality");
        const state = getAddressComponent("administrative_area_level_1");
        const pinCode = getAddressComponent("postal_code");

        let cleanedAddress = place.formatted_address;
        if (city) cleanedAddress = cleanedAddress.replace(city, "").trim();
        if (state) cleanedAddress = cleanedAddress.replace(state, "").trim();
        if (pinCode)
          cleanedAddress = cleanedAddress.replace(pinCode, "").trim();
        cleanedAddress = cleanedAddress
          .replace(/,\s*$/, "")
          .replace(/\s{2,}/g, " ");

        // Dispatch coordinates
        dispatch(
          setCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          })
        );

        // Dispatch address details
        dispatch(
          setAddressDetails({
            address: cleanedAddress || "",
            city,
            state,
            pinCode,
          })
        );

        setSearchQuery(place.formatted_address || "");
        setSearchResults([]);
        setIsMapVisible(true);
      } else {
        console.error("Failed to fetch place details:", status);
      }
    });
  };

  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>

      <div className="pt-5">
        <div className="flex items-center gap-3 px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12">
          <p className="title-sm font-semibold">Change delivery location</p>
        </div>
      </div>

      <div className="my-5">
        <div className="mx-[1.85rem] md:mx-10 md2:mx-14 lg:mx-10 xl:mx-12 bg-formisGrey/70 flex flex-col gap-4 rounded-[10px] mb-5">
          <div className="flex items-center py-2 px-4 gap-4">
            <FaLocationDot className="text-xl" />
            <input
              className="bg-transparent outline-none w-full"
              placeholder="Search address"
              onChange={(e) => {
                setSearchQuery(e.target.value);
                handlePlaceSearch(e.target.value);
              }}
              value={searchQuery}
            />
          </div>
        </div>

        <button
          onClick={handleUseLocationClick}
          className="px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12 flex gap-3 items-center"
        >
          <div className="bg-formisGrey/70 rounded-[5px] p-3">
            <BiCurrentLocation className="text-xl" />
          </div>
          <p className="font-semibold border-b border-formisBlack">
            Use your current location
          </p>
        </button>
      </div>

      {searchResults.length > 0 && (
        <div className="px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12">
          {searchResults.map((result) => (
            <div
              key={result.place_id}
              onClick={() => handleSearchResultClick(result.place_id)}
              className="py-2 hover:bg-formisGrey/20 flex items-center gap-3 rounded-[10px] cursor-pointer"
            >
              <div className="bg-formisGrey/40 p-3 rounded-[10px]">
                <FaLocationDot className="text-xl" />
              </div>
              <p>{result.description}</p>
            </div>
          ))}
        </div>
      )}

      {isMapVisible && (
        <div>
          <div className="relative mx-5 mb-12">
            <GoogleMap />
            <div className="flex items-center justify-center sticky md:fixed bottom-16 left-0 bg-formisGrey w-full py-5">
              <Link to="/get-address">
                <button
                  // onClick={() => handlePageChange("address")}
                  className="bg-formisBlack text-formisPeach px-10 py-3 rounded-full"
                >
                  Confirm Location and Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}

      <Services />
      <Footer />
    </div>
  );
};

export default LocationPage;
