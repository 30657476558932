import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import { useEffect, useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { advertisementsApi } from "../../api";

const Advertisements = () => {
  const [advertisements, setAdvertisements] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const fetchAdvertisements = async () => {
    try {
      const response = await fetch(
        advertisementsApi,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setAdvertisements(data);
      }
    } catch (error) {
      console.error("Failed to fetch advertisements", error);
    }
  };

  useEffect(() => {
    fetchAdvertisements();
  }, []);

  return (
    <div className="relative w-screen bg-formisWhite text-formisBlack">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1.075}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".next-button",
          prevEl: ".prev-button",
        }}
        loop={true}
        speed={600}
        breakpoints={{
          200: {
            slidesPerView: 1.185,
            spaceBetween: 25,
          },
          600: {
            slidesPerView: 1.135,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 1.075,
            spaceBetween: 30,
          },
          1280: {
            slidesPerView: 1.055,
            spaceBetween: 30,
          },
        }}
        onSlideChange={(swiper) =>
          setActiveIndex(swiper.realIndex % advertisements.length)
        }
      >
        {advertisements.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage: `url(${slide.image})` }}
              className="relative bg-[30%] bg-clip-content bg-cover h-[60vh] md2:h-[80vh] rounded-[15px] lg:rounded-[20px] cursor-grab"
            >
              <div className="absolute inset-0 bg-formisBlack/40 h-full rounded-[15px] lg:rounded-[20px] z-10"></div>
              <div
                className="absolute top-0 z-20 w-full h-[60vh] md2:h-[80vh] 
                  flex flex-col justify-end text-formisPeach px-5 md:px-9 lg:px-9 2xl:px-28 pb-12 md2:pb-16 pointer-events-none"
              >
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between w-full pointer-events-none mb-5 lg:mb-0">
                  <h1 className="title-md font-bold lg:w-[50%] mb-5 lg:mb-0">
                    {slide.heading}
                  </h1>
                  <a
                    href={slide.link}
                    className="pointer-events-auto w-fit text-sm md2:text-base text-formisBlack bg-formisPeach lg:place-self-end py-4 px-8 rounded-full font-[500] md2:font-[600]"
                  >
                    Check Now
                  </a>
                </div>

                <hr className="hidden lg:block border-t border-formisPeach/40 mt-8 mb-6 2xl:my-10" />
              </div>
            </div>
          </SwiperSlide>
        ))}
        {advertisements.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage: `url(${slide.image})` }}
              className="relative bg-[30%] bg-clip-content bg-cover h-[60vh] md2:h-[80vh] rounded-[15px] lg:rounded-[20px] cursor-grab"
            >
              <div className="absolute inset-0 bg-formisBlack/40 h-full rounded-[15px] lg:rounded-[20px] z-10"></div>
              <div
                className="absolute top-0 z-20 w-full h-[60vh] md2:h-[80vh] 
                  flex flex-col justify-end text-formisPeach px-5 md:px-9 lg:px-9 2xl:px-28 pb-12 md2:pb-16 pointer-events-none"
              >
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between w-full pointer-events-none mb-5 lg:mb-0">
                  <h1 className="title-md font-bold lg:w-[50%] mb-5 lg:mb-0">
                    {slide.heading}
                  </h1>
                  <a
                    href={slide.link}
                    className="pointer-events-auto w-fit text-sm md2:text-base text-formisBlack bg-formisPeach lg:place-self-end py-4 px-8 rounded-full font-[500] md2:font-[600]"
                  >
                    Check Now
                  </a>
                </div>

                <hr className="hidden lg:block border-t border-formisPeach/40 mt-8 mb-6 2xl:my-10" />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="text-formisPeach absolute bottom-10 px-14 md:px-20 lg:px-20 2xl:px-40 flex items-center w-full justify-between z-20 pointer-events-auto">
        <HiArrowLongLeft className="hidden md2:block prev-button text-4xl cursor-pointer" />
        {/* Pagination Dots */}
        <div className="flex place-self-end gap-4 pl-2 lg:pl-0">
          {advertisements.map((_, index) => (
            <div
              key={index}
              className={`p-[2px] transition-all h-fit rounded-full ${
                activeIndex === index
                  ? "bg-formisBlack border-2 border-formisPeach"
                  : "bg-formisPeach border-2 border-formisPeach"
              }`}
            ></div>
          ))}
        </div>
        <HiArrowLongRight className="hidden md2:block next-button text-4xl cursor-pointer" />
      </div>
    </div>
  );
};

export default Advertisements;
