import { useState } from "react";
import {
  addressesApi,
  addToCartApi,
  sendOtpApi,
  verifyOtpApi,
} from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setAddressDetails, setCoordinates } from "../../features/mapSlice";

const LoginPage = ({ handlePageChange, getCartDetails }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState();
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [phoneSubmitData, setPhoneSubmitData] = useState();

  const cartItems = useSelector((state) => state.cartItems.items);
  const { coordinates, address, address2, city, state, pinCode, addressType } =
    useSelector((state) => state.googleMapLocation);

  const handleAddAddress = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const getResponse = await fetch(addressesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      // if (!getResponse.ok) {
      //   throw new Error("Failed to fetch addresses");
      // }

      const savedAddresses = await getResponse.json();

      const matchedAddress = savedAddresses.results.find((addr) => {
        const isLatLngMatch =
          parseFloat(addr.latitude).toFixed(6) ===
            parseFloat(coordinates.lat).toFixed(6) &&
          parseFloat(addr.longitude).toFixed(6) ===
            parseFloat(coordinates.lng).toFixed(6);

        const isAddressTypeMatch = addr.name === addressType;

        return isLatLngMatch || isAddressTypeMatch;
      });

      if (matchedAddress) {
        dispatch(
          setAddressDetails({
            address: matchedAddress.address_line_1,
            address2: matchedAddress.address_line_2 || "",
            city: matchedAddress.city,
            state: matchedAddress.state,
            pinCode: matchedAddress.postal_code,
            addressType: matchedAddress.name,
            addressId: matchedAddress.id,
          })
        );
        dispatch(
          setCoordinates({
            lat: matchedAddress.latitude,
            lng: matchedAddress.longitude,
          })
        );
        handlePageChange("");
      } else {
        const postResponse = await fetch(addressesApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify({
            name: addressType,
            address_line_1: address,
            address_line_2: address2 || null,
            city: city,
            state: state,
            latitude: parseFloat(coordinates.lat.toFixed(6)),
            longitude: parseFloat(coordinates.lng.toFixed(6)),
            postal_code: pinCode,
          }),
        });

        const data = await postResponse.json();

        // if (postResponse.ok) {
        //   console.log("post resp error from login: ", postResponse)
        // }
        dispatch(
          setAddressDetails({
            address,
            address2,
            city,
            state,
            pinCode,
            addressType,
            addressId: data.id,
          })
        );
        handlePageChange("");
      }
    } catch (error) {
      // console.log("Error from login address call: ", error);
    }
  };

  const handleAddToCartApiCall = async (quantity, product_id) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id,
              quantity,
            },
          ],
        }),
      });
      if (response.ok) {
        return;
      }
    } catch {}
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(sendOtpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobile,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setStep(2);
        setPhoneSubmitData(data);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(verifyOtpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobile,
          otp: otp,
          ...(phoneSubmitData.new_user && {
            first_name: firstName,
            last_name: lastName,
          }),
        }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        localStorage.setItem("isAuthenticated", true);
        await handleAddAddress();
        for (const item of cartItems) {
          await handleAddToCartApiCall(item.quantity, item.product_id);
        }
        await getCartDetails();
        handlePageChange("");
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full">
      <div className="pt-14 pb-24 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12">
        <h2 className="title-xl font-extrabold leading-none text-center mb-8">
          Login
        </h2>

        {step === 1 && (
          <form
            onSubmit={handlePhoneSubmit}
            className="flex flex-col gap-5 mb-12"
          >
            <input
              placeholder="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              required
              type="number"
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
            />
            <button
              type="submit"
              className="w-full bg-formisBlack text-formisPeach py-4 px-6 rounded-full button-font font-[600]"
            >
              {loading ? <div className="loader mx-auto"></div> : "Send OTP"}
            </button>
          </form>
        )}

        {step === 2 && (
          <form
            onSubmit={handleOtpSubmit}
            required
            className="flex flex-col gap-5 mb-12"
          >
            <input
              placeholder="Mobile Number"
              disabled
              value={mobile}
              type="number"
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none disabled:"
            />
            {phoneSubmitData && phoneSubmitData.new_user && (
              <div className="flex flex-col gap-5">
                <input
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  type="text"
                  className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
                />
                <input
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  type="text"
                  className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
                />
              </div>
            )}
            <input
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
            />
            <button
              type="submit"
              className="w-full bg-formisBlack text-formisPeach py-4 px-6 rounded-full button-font font-[600]"
            >
              {loading ? <div className="loader mx-auto"></div> : "Submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
