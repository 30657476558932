import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Footer from "../components/Footer";

const AllProducts = () => {
  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <div
          style={{
            backgroundImage: `url(https://concept-theme-tech.myshopify.com/cdn/shop/collections/all-products.webp?v=1709198878&width=600)`,
          }}
          className="relative h-[50vh] md:h-[65vh] xl:h-[70vh] bg-no-repeat bg-cover bg-[50%] bg-formisBlack rounded-t-[20px]"
        >
          <div className="h-full w-full absolute top-0 left-0 bg-formisBlack/70" />

          <Header transparent={true} />

          <div className="relative top-20 z-10">
            <div className="px-4 flex max-w-[30%] mx-auto items-center text-formisBlack bg-formisWhite/50 rounded-[10px] backdrop-blur-md">
              <svg
                className="w-7 cursor-pointer"
                viewBox="0 0 20 20"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.4007 17.4998L15.3707 14.4698M15.3707 14.4698C16.7279 13.1126 17.5674 11.2376 17.5674 9.1665C17.5674 5.02437 14.2095 1.6665 10.0674 1.6665C5.92525 1.6665 2.56738 5.02437 2.56738 9.1665C2.56738 13.3086 5.92525 16.6665 10.0674 16.6665C12.1385 16.6665 14.0135 15.827 15.3707 14.4698Z"
                ></path>
              </svg>
              <input
                placeholder={`Search in All Products`}
                className="bg-transparent font-[500] md2:text-2xl pl-2 md:pl-5 py-2 w-full placeholder:text-formisBlack/60 outline-none"
              />
            </div>
          </div>

          <div className="relative flex items-end h-[calc(100%-9rem)] md:h-[calc(100%-12rem)] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 text-formisPeach">
            <div>
              <div className="flex items-center gap-4 md:gap-6 text-[13px] lg:text-[14px] xl:text-[16px] leading-none">
                <svg
                  className="w-4"
                  viewBox="0 0 20 21"
                  stroke="currentColor"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.333 14.667v-3.713c0-1.355 0-2.033-.165-2.66a5 5 0 0 0-.818-1.702c-.387-.521-.916-.945-1.974-1.791l-.378-.303h0c-1.784-1.427-2.676-2.14-3.665-2.414a5 5 0 0 0-2.666 0c-.99.274-1.881.987-3.665 2.414h0l-.378.303c-1.058.846-1.587 1.27-1.974 1.79a5 5 0 0 0-.818 1.703c-.165.627-.165 1.305-.165 2.66v3.713a4.167 4.167 0 0 0 4.166 4.166c.92 0 1.667-.746 1.667-1.666v-3.334a2.5 2.5 0 0 1 5 0v3.334c0 .92.746 1.666 1.667 1.666a4.167 4.167 0 0 0 4.166-4.166Z"
                  ></path>
                </svg>
                <span>/</span>
                <p>Collections</p>
                <span>/</span>
                <p>All products</p>
              </div>
              <h2 className="title-xl leading-none font-bold my-5">
                All products
              </h2>
            </div>
          </div>

          <div className="bg-formisWhite absolute bottom-0 left-0 w-full h-10 rounded-t-[15px]"></div>
        </div>
      </div>

      <div className="hidden xl:flex items-center bg-formisWhite px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-12">
        <button className="flex items-center gap-2 border-2 border-formisBlack rounded-full py-4 px-7 mr-12">
          <svg
            className="w-6"
            viewBox="0 0 22 20"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              d="M1.5 14H4M4 14C4 15.6569 5.34315 17 7 17C8.65685 17 10 15.6569 10 14C10 12.3431 8.65685 11 7 11C5.34315 11 4 12.3431 4 14ZM18 6H20.5M18 6C18 7.65685 16.6569 9 15 9C13.3431 9 12 7.65685 12 6C12 4.34315 13.3431 3 15 3C16.6569 3 18 4.34315 18 6ZM13 14H20.5M1.5 6H9"
            ></path>
          </svg>
          <span className="font-button font-[600]">Show Filters</span>
        </button>

        <ul className="flex items-center text-[22px] font-semibold leading-none text-formisBlack/40">
          <li className="cursor-pointer hover:text-formisBlack transition-colors duration-300">
            Headphones
          </li>
          <li className="px-5">/</li>
          <li className="cursor-pointer hover:text-formisBlack transition-colors duration-300">
            Earphones
          </li>
          <li className="px-5">/</li>
          <li className="cursor-pointer hover:text-formisBlack transition-colors duration-300">
            Speakers
          </li>
          <li className="px-5">/</li>
          <li className="cursor-pointer hover:text-formisBlack transition-colors duration-300">
            Accessories
          </li>
        </ul>
      </div>

      <div className="bg-formisWhite rounded-t-[10px] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20">
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 bundle-card-gap">
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
      </div>

      <Services />
      <Footer />
    </div>
  );
};

export default AllProducts;

const ProductCard = () => {
  return (
    <div>
      <img
        src="https://concept-theme-tech.myshopify.com/cdn/shop/files/resonance-oasisflow-sand-01.webp?v=1708606611&width=1080"
        alt="Product"
        className="rounded-t-[10px]"
      />
      <div className="bg-[#fafafa] p-4 rounded-b-[10px]">
        <span className="text-[10px] tracking-widest">SONICPULSE</span>
        <p className="font-[600] lg:text-[19px] leading-tight my-2">
          Air Beats Gold Tone
        </p>
        <p className="text-sm font-[600]">Rs. 42,900</p>
      </div>
    </div>
  );
};
