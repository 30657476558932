import { TiArrowSortedDown } from "react-icons/ti";
import { useSelector } from "react-redux";

const CartFooter = ({ userCart, handlePageChange }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const cartItems = useSelector((state) => state.cartItems.items);
  const activeCart = isAuthenticated ? userCart : cartItems;

  const totalPrice = activeCart?.reduce((total, item) => {
    return total + item.quantity * item.selling_price_at_time;
  }, 0);

  const { address, addressType } = useSelector(
    (state) => state.googleMapLocation
  );

  return (
    <div>
      <div className="px-5 py-3 bg-formisGrey">
        <div className="flex justify-between items-center gap-2">
          <div className="min-w-[45%] text-xs">
            Delivering to
            <div
              onClick={() => handlePageChange("location")}
              className="cursor-pointer"
            >
              {address && addressType ? (
                <p className="flex items-center leading-none text-sm">
                  <span className="font-bold">{addressType}</span> -{" "}
                  {address.length > 15 ? address.slice(0, 10) + "..." : address}
                  <TiArrowSortedDown className="ml-2" />
                </p>
              ) : (
                <p className="flex items-center leading-none text-xs md:text-sm">
                  Select Address
                  <TiArrowSortedDown className="ml-2" />
                </p>
              )}
            </div>
          </div>

          {isAuthenticated ? (
            <button className="flex items-center justify-between py-3 px-4 text-sm bg-formisBlack text-formisPeach rounded-full w-full">
              <div className="flex flex-col">
                <p className="font-bold leading-none tracking-tight">
                  ₹{totalPrice?.toFixed(2)}/-
                </p>
                <span className="text-left text-xs">TOTAL</span>
              </div>
              <p>Place Order</p>
            </button>
          ) : (
            <button onClick={()=>handlePageChange("login")} className="flex items-center justify-center py-3 px-4 text-sm bg-formisBlack text-formisPeach rounded-full w-full">
              Login to Place Order
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartFooter;
