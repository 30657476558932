import { useSelector } from "react-redux";
import ProductCard from "./Search Product Components/ProductCard";
import ProductFAQ from "./Search Product Components/ProductFAQ";
import ProductFeatures from "./Search Product Components/ProductFeatures";
import { useEffect, useState } from "react";

const ProductPage = ({ userCart, getCartDetails }) => {
  const slug = useSelector((state) => state.search.whichProduct);
  const [productData, setProductData] = useState();

  const fetchProduct = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/${slug}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductData(data);
        // console.log("Single Product Data:", data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <div className="bg-white mt-5 overflow-scroll">
      {productData && (
        <div className="bg-white px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20">
          <ProductCard product={productData} userCart={userCart} getCartDetails={getCartDetails} />
          <ProductFeatures product={productData} />
          {productData.questions !== "NA" && (
            <ProductFAQ product={productData} />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductPage;
