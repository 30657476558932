import { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import { HiArrowLongRight } from "react-icons/hi2";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

import { categories } from "../../categoriesData";
import { useDispatch } from "react-redux";
import { toggleSearchComponent } from "../../features/searchSlice";
import { categoriesMainPageApi } from "../../api";

import he from "../../assets/he.png"

const Products = () => {
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [categoriesData, setCategoriesData] = useState();

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(categoriesMainPageApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setCategoriesData(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="bg-formisWhite px-5 lg:px-9 xl:px-14 py-20">
      <div className="mb-14 lg:mb-20 lg:flex lg:gap-20 3xl:px-28">
        <div className="lg:w-[35%]">
          <h1 className="title-md font-bold mb-4 md:mb-6 lg:mb-8 xl:mb-12">
            People believe in FORMIS
          </h1>
          <button className="flex items-center gap-2 border-2 rounded-full border-formisBlack py-2 md2:py-4 px-4 md2:px-6 text-[12px] md2:text-[16px] mb-5">
            Our Story <HiArrowLongRight className="text-2xl" />
          </button>
        </div>
        <div className="lg:w-[60%] xl:text-[18px] font-medium">
          <p className="mb-5">
            Formis redefines healthcare, merging Bridge's personalized care,
            Advantage's analytical precision, and UnityOne's expansive
            enterprise capabilities into a seamless, innovative care continuum.
          </p>
          <p>
            SIMPLE, SMART AND SEAMLESS BRIDGING THE GAP IN PROACTIVE AND
            CONTINUOUS CARE DELIVERY.
          </p>
        </div>
      </div>

      <div className="relative group">
        <Swiper
          modules={[Navigation, Mousewheel]}
          mousewheel={{
            forceToAxis: true,
          }}
          navigation={{
            nextEl: ".product-next",
            prevEl: ".product-prev",
          }}
          spaceBetween={20}
          slidesPerView={1.25}
          breakpoints={{
            200: {
              slidesPerView: 1.25,
            },
            600: {
              slidesPerView: 2.75,
            },
            1024: {
              slidesPerView: 3.15,
            },
            1280: {
              slidesPerView: 4.15,
            },
          }}
          onSlideChange={handleSlideChange}
        >
          <SwiperSlide>
            <div
              style={{
                backgroundImage: `url(${he})`,
              }}
              className="max-w-[350px] cursor-pointer relative h-[40vh] md:h-[35vh] md2:h-[40vh] xl:h-[50vh] bg-cover bg-no-repeat rounded-[20px] group"
              // onClick={() => navigate("/all-products")}
              onClick={() => dispatch(toggleSearchComponent())}
            >
              <div className="cursor-pointer absolute top-0 left-0 bg-formisBlack/60 h-full w-full rounded-[20px]"></div>

              <div className="text-formisPeach relative z-10 px-5 pb-6 2xl:px-10 xl:pb-7 h-full flex items-end">
                <div className="flex justify-between w-full items-center">
                  <div>
                    <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                      All Products
                    </h2>
                    <p className="text-[12px]">Check out all our products</p>
                  </div>
                  <div>
                    <HiArrowLongRight className="text-xl lg:text-2xl" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {categoriesData &&
            categoriesData.map((category, index) => (
              <SwiperSlide key={index}>
                <div
                  className="max-w-[350px] cursor-pointer bg-formisBlack relative h-[40vh] md:h-[35vh] md2:h-[40vh] xl:h-[50vh] bg-[35%] rounded-[20px]"
                  onClick={() => dispatch(toggleSearchComponent())}
                >
                  <div className="relative z-10 h-full flex flex-col items-end text-formisBlack bg-formisWhite rounded-[20px]">
                    <img
                      src={category.image}
                      className="h-[80%] rounded-t-[20px]"
                      alt={category.name}
                    />
                    <div className="h-[35%] flex justify-between w-full items-center px-5 2xl:px-5 bg-formisPeach rounded-b-[20px]">
                      <div>
                        <h2 className="text-xl lg:text-2xl 2xl:text-2xl font-bold mb-1">
                          {category.name}
                        </h2>
                        <p className="text-[12px]">{category.info}</p>
                      </div>
                      <div>
                        <HiArrowLongRight className="text-xl lg:text-2xl" />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="absolute top-1/2 left-0 z-10 w-full hidden lg:flex justify-between px-10 transition-opacity opacity-0 group-hover:opacity-100 duration-500">
          <button
            disabled={activeIndex === 0}
            className={`${
              activeIndex === 0 ? "opacity-0" : "cursor-pointer"
            } bg-formisBlack/60 rounded-full p-4 text-2xl text-formisPeach product-prev`}
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
          <button
            className={`${
              activeIndex === categories.length - 1 ? "opacity-0" : ""
            } bg-formisBlack/60 rounded-full p-4 text-2xl text-formisPeach cursor-pointer product-next`}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Products;
