import { useSelector } from "react-redux";
import CartProduct from "./Search Cart Components/CartProduct";
import NothingInCart from "./Search Cart Components/NothingInCart";
import CartFooter from "./Search Cart Components/CartFooter";
import { useEffect } from "react";
import CartDetails from "./Search Cart Components/CartDetails";
import CartProductApi from "./Search Cart Components/CartProductApi";

const CartPage = ({ handlePageChange, userCart, getCartDetails }) => {
  const { items } = useSelector((state) => state.cartItems);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  // useEffect(() => {
  //   console.log("Items Store:", items);
  // }, [items]);

  return (
    <div className="relative overflow-scroll h-full">
      {(isAuthenticated !== "true" && items.length > 0) || (isAuthenticated==='true' && userCart?.length>0) ? (
        <div className="h-full flex flex-col justify-between pt-5">
          <div className="mb-4">
            {isAuthenticated !== "true"
              ? items.map((item, index) => (
                  <CartProduct item={item} key={index} />
                ))
              : userCart?.map((item, index) => (
                  <CartProductApi
                    item={item}
                    userCart={userCart}
                    key={item.product_id}
                    getCartDetails={getCartDetails}
                  />
                ))}
          </div>
          <div className="pb-28 px-5">
            <CartDetails userCart={userCart} />
          </div>
          <div className="fixed bottom-0 right-0 w-full">
            <CartFooter userCart={userCart} handlePageChange={handlePageChange} />
          </div>
        </div>
      ) : (
        <NothingInCart handlePageChange={handlePageChange} />
      )}
    </div>
  );
};

export default CartPage;
