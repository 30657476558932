import { useEffect, useState } from "react";
import MainPage from "./Search Components/MainPage";
import { useLocation, useNavigate } from "react-router-dom";
import SearchPage from "./Search Components/SearchPage";
import ProductPage from "./Search Components/ProductPage";
import CartPage from "./Search Components/CartPage";
import SearchTopbar from "./Search Components/SearchTopbar";
import CategoryProductsPage from "./Search Components/CategoryProductsPage";
import LocationPage from "./Search Components/LocationPage";
import AddressPage from "./Search Components/AddressPage";
import LoginPage from "./Search Components/LoginPage";
import { useSelector } from "react-redux";
import ProfilePage from "./Search Components/ProfilePage";
import { IoIosArrowForward } from "react-icons/io";
import { getCartDetailsApi } from "../api";
import SavedAddresses from "./Search Components/SavedAddresses";
import useRefresh from "../hooks/useRefresh";

const Search = ({ isSearchOpen, toggleSearch }) => {
  const [whichPage, setWhichPage] = useState("main");
  const location = useLocation();
  const navigate = useNavigate();

  const items = useSelector((state) => state.cartItems.items);

  const showCart =
    new URLSearchParams(location.search).get("search") === "cart" ||
    new URLSearchParams(location.search).get("search") === "location" ||
    new URLSearchParams(location.search).get("search") === "address";

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const [userCart, setUserCart] = useState([]);

  const activeCart = isAuthenticated==="true" ? userCart : items;

  const discountAmount = activeCart.reduce((total, item) => {
    return (
      total + item.quantity * item.discount_amount || item.quantity * item.discounted_amount
    );
  }, 0);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageParam = queryParams.get("search");
    if (pageParam) {
      setWhichPage(pageParam);
    } else {
      setWhichPage("main");
    }
  }, [location]);

  const handlePageChange = (pageName) => {
    setWhichPage(pageName);
    navigate(`?search=${pageName}`);
  };

  const { refreshAccessToken } = useRefresh();

  const getCartDetails = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(getCartDetailsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("User Cart", data.cart);
        setUserCart(data.cart);
      } else if (response.status === 401) {
        // refreshAccessToken();
        localStorage.setItem("isAuthenticated", false)
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) getCartDetails();
  }, []);

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isSearchOpen ? "bg-formisBlack/30 z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={toggleSearch}
      />
      <div
        id="search-modal-main"
        className={`fixed md2:top-0 md2:right-0 bg-white z-[31] rounded-t-[20px] md2:rounded-t-none md2:rounded-tl-[20px] md2:rounded-l-[20px] w-screen h-[92dvh] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:pt-10 md2:h-screen
               transition-transform duration-500 flex flex-col ${
                 isSearchOpen
                   ? "slide-up md2:translate-x-0"
                   : "slide-down md2:translate-x-full"
               }`}
      >
        <SearchTopbar handlePageChange={handlePageChange} />

        {whichPage === "main" && (
          <MainPage
            handlePageChange={handlePageChange}
            userCart={userCart}
            getCartDetails={getCartDetails}
          />
        )}
        {whichPage === "search" && (
          <SearchPage handlePageChange={handlePageChange} userCart={userCart} getCartDetails={getCartDetails} />
        )}
        {whichPage === "product" && (
          <ProductPage userCart={userCart} getCartDetails={getCartDetails} />
        )}
        {whichPage === "cart" && (
          <CartPage
            handlePageChange={handlePageChange}
            userCart={userCart}
            getCartDetails={getCartDetails}
          />
        )}
        {whichPage === "category" && (
          <CategoryProductsPage
            handlePageChange={handlePageChange}
            userCart={userCart}
            getCartDetails={getCartDetails}
          />
        )}
        {whichPage === "location" && (
          <LocationPage handlePageChange={handlePageChange} />
        )}
        {whichPage === "address" && (
          <AddressPage handlePageChange={handlePageChange} />
        )}
        {whichPage === "login" && (
          <LoginPage handlePageChange={handlePageChange} getCartDetails={getCartDetails} />
        )}
        {whichPage === "profile" && (
          <ProfilePage handlePageChange={handlePageChange} />
        )}
        {whichPage === "addresses" && (
          <SavedAddresses handlePageChange={handlePageChange} />
        )}

        {/* Floating View Cart Buttons */}
        {/* If user is not logged in  */}
        {items.length > 0 && !showCart && isAuthenticated !== "true" && (
          <div className="absolute w-full bottom-2 px-5">
            <button
              onClick={() => handlePageChange("cart")}
              className="shadow-xl border border-formisBlack/10 w-full bg-formisPeach py-2 rounded-full text-formisBlack
                          flex items-center justify-between px-2"
            >
              <div className="flex items-center justify-center gap-2">
                <div className="cart-items-images flex">
                  {items.slice(0, 3).map((item, index) => (
                    <img
                      src={item.image_url}
                      alt={item.name}
                      key={index}
                      className="size-10 rounded-full border border-formisBlack/50"
                    />
                  ))}
                </div>
                <div>
                  <p className="text-left text-sm">
                    {items.length} {items.length === 1 ? "item" : "items"}
                  </p>
                  <p className="text-[10px] font-bold text-green-700">
                    You Save ₹{discountAmount.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <div className="bg-formisBlack/10 p-3 text-xl rounded-full flex items-center gap-2">
                  <p className="text-base leading-none font-[600]">View Cart</p>
                  <IoIosArrowForward />
                </div>
              </div>
            </button>
          </div>
        )}

        {/* If user is logged in */}
        {isAuthenticated === "true" && !showCart && userCart?.length > 0 && (
          <div className="absolute w-full bottom-2 px-5">
            <button
              onClick={() => handlePageChange("cart")}
              className="shadow-xl border border-formisBlack/10 w-full bg-formisPeach py-2 rounded-full text-formisBlack
                      flex items-center justify-between px-2"
            >
              <div className="flex items-center justify-center gap-2">
                <div className="cart-items-images flex">
                  {userCart?.slice(0, 3).map((item, index) => (
                    <img
                      src={item.product_image}
                      alt={item.product_name}
                      key={index}
                      className="size-10 rounded-full border border-formisBlack/50"
                    />
                  ))}
                </div>
                <div>
                  <p className="text-left text-sm">
                    {userCart.length} {userCart.length === 1 ? "item" : "items"}
                  </p>
                  <p className="text-[10px] font-bold text-green-700">
                    You Save ₹{discountAmount.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <div className="bg-formisBlack/10 p-3 text-xl rounded-full flex items-center gap-2">
                  <p className="text-base leading-none font-[600]">View Cart</p>
                  <IoIosArrowForward />
                </div>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
