import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Footer from "../components/Footer";
import { PiArrowArcLeftLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import Floater from "../components/Floater";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "../features/authSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState();
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [phoneSubmitData, setPhoneSubmitData] = useState();

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://kitsa.co.in/api/v1/auth/send-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobile,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setStep(2);
        setPhoneSubmitData(data);
        console.log("Mobile Submit", data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(
        "https://kitsa.co.in/api/v1/auth/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            mobile_number: mobile,
            otp: otp,
            ...(phoneSubmitData.new_user && {
              first_name: firstName,
              last_name: lastName,
            }),
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        localStorage.setItem("isAuthenticated", true)
        console.log("OTP Submit", data);
        navigate("/")
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>

      <div className="pt-14 pb-24 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12">
        <h2 className="title-xl font-extrabold leading-none text-center mb-8">
          Login
        </h2>

        {step === 1 && (
          <form
            onSubmit={handlePhoneSubmit}
            className="flex flex-col mx-auto gap-5 mb-12 max-w-[512px]"
          >
            <input
              placeholder="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              required
              type="number"
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
            />
            <button
              type="submit"
              className="w-full bg-formisBlack text-formisPeach py-4 px-6 rounded-full button-font font-[600]"
            >
              {loading ? <div className="loader mx-auto"></div> : "Send OTP"}
            </button>
          </form>
        )}

        {step === 2 && (
          <form
            onSubmit={handleOtpSubmit}
            required
            className="flex flex-col max-w-[512px] mx-auto gap-5 mb-12"
          >
            <input
              placeholder="Mobile Number"
              disabled
              value={mobile}
              type="number"
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none disabled:"
            />
            {phoneSubmitData && phoneSubmitData.new_user && (
              <div className="flex flex-col gap-5">
                <input
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  type="text"
                  className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
                />
                <input
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  type="text"
                  className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
                />
              </div>
            )}
            <input
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
              className="px-6 py-4 w-full md:max-w-[512px] mx-auto rounded-[10px] bg-formisGrey/50 outline-none"
            />
            <button
              type="submit"
              className="w-full bg-formisBlack text-formisPeach py-4 px-6 rounded-full button-font font-[600]"
            >
              {loading ? <div className="loader mx-auto"></div> : "Submit"}
            </button>
          </form>
        )}

        <Link to="/" className="flex items-center gap-2 justify-center mt-8">
          <PiArrowArcLeftLight className="text-2xl" />
          <p className="text-lg border-b border-formisBlack leading-none pb-1">
            Return to Store
          </p>
        </Link>
      </div>

      <Services />
      <Footer />

      <Floater />
    </div>
  );
};

export default Login;
