import { useState } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

const ComparisonSlider = () => {
  const [labelOpacity, setLabelOpacity] = useState(1);

  return (
    <div
      className="px-5 lg:px-9 xl:px-14 pt-16 md:pt-20 xl:pt-28 pb-7 md:pb-14 rounded-t-[20px] 2xl:rounded-t-[30px] 
                    relative bottom-10 z-[3] bg-formisWhite text-formisBlack"
    >
      <p className="text-base md2:text-[17px] lg:text-[18px] xl:text-xl text-center mb-3 lg:mb-4 font-[500] tracking-normal">
        Sound in Spectrum
      </p>
      <h2 className="title-md leading-none font-bold text-center mb-8 xl:mb-9 2xl:mb-12">
        Vibrant Headphone Choices
      </h2>

      <div className="rounded-[10px] md:rounded-[15px] lg:rounded-[20px]">
        <ReactCompareSlider
          onPointerDown={() => setLabelOpacity(0)}
          onPointerUp={() => setLabelOpacity(1)}
          onMouseLeave={() => setLabelOpacity(1)}
          boundsPadding={20}
          onlyHandleDraggable={true}
          handle={<CustomHandle />}
          itemOne={
            <div className="relative w-full h-full">
              <div
                style={{ opacity: labelOpacity }}
                className="absolute bottom-5 md:bottom-10 left-5 md:left-10 transition-opacity text-formisWhite"
              >
                <p className="text-xs md:text-lg font-medium">Flow Harmony</p>
                <p className="text-base md:text-3xl font-medium md:font-bold tracking-tight md:tracking-normal">Gold Tone</p>
              </div>
              <ReactCompareSliderImage
                src="https://formis-for-essentials-public.s3.ap-south-1.amazonaws.com/web/advertisement/2+1.png"
                className="rounded-[10px] md:rounded-[15px] lg:rounded-[20px] min-h-[35vh] xl:max-h-[80vh]"
                alt="Gold Tone"
              />
            </div>
          }
          itemTwo={
            <div className="relative w-full h-full">
              <div
                style={{ opacity: labelOpacity }}
                className="absolute bottom-5 md:bottom-10 right-5 md:right-10 transition-opacity text-formisWhite"
              >
                <p className="text-xs md:text-lg font-medium place-self-end">Flow Harmony</p>
                <p className="text-base md:text-3xl font-medium md:font-bold tracking-tight md:tracking-normal">Crimson Shadow</p>
              </div>
              <ReactCompareSliderImage
                src="https://formis-for-essentials-public.s3.ap-south-1.amazonaws.com/web/advertisement/1+1.png"
                className="rounded-[10px] md:rounded-[15px] lg:rounded-[20px] min-h-[35vh] xl:max-h-[80vh]"
                alt="Crimsown Shadow"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ComparisonSlider;

const CustomHandle = () => {
  return (
    <div className="h-full flex items-center justify-center cursor-col-resize">
      <div className="absolute h-full border-2 border-formisWhite"></div>
      <div className="w-10 h-16 bg-white rounded-full shadow-lg relative flex items-center justify-center scale-[.70] lg:scale-[.85] xl:scale-100">
        <div className="h-4 w-[2px] bg-gray-800 rounded-full"></div>
        <div className="h-4 w-[2px] bg-gray-800 rounded-full ml-1"></div>
        <div className="h-4 w-[2px] bg-gray-800 rounded-full ml-1"></div>
      </div>
    </div>
  );
};
