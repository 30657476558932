import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import OrderHistory from "./OrderHistory";
import Profile from "./Profile";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const MobileMain = ({isMenuOpen, toggleMenu, expandedMobileMenu, setExpandedMobileMenu}) => {
  return (
    <div className="lg:hidden relative text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isMenuOpen ? "bg-formisBlack/50 z-20" : "bg-transparent -z-50"
        } fixed inset-0`}
        onClick={toggleMenu}
      />
      <div
        className={`fixed inset-x-0 bottom-0 bg-formisWhite z-[31] rounded-t-[20px] h-[91svh] flex flex-col justify-between
                   transition-transform duration-500 ${
                     isMenuOpen ? "translate-y-0" : "translate-y-full"
                   }`}
      >
        <div>
          <div onClick={toggleMenu} className="h-12 pt-4">
            <div className="mx-auto w-16 h-1 bg-formisGrey rounded-full"></div>
          </div>
          <ul id="mobile-menu-list" className="text-[24px] pt-4 font-bold px-5">
            <li
              className="py-[5px] flex items-center justify-between"
              onClick={() => setExpandedMobileMenu(1)}
            >
              <p>Order History</p>
              <MdOutlineKeyboardArrowRight className="text-3xl text-formisBlack/50" />
            </li>
            <li
              className="py-[5px] flex items-center justify-between"
              onClick={() => setExpandedMobileMenu(2)}
            >
              <p>Profile</p>
              <MdOutlineKeyboardArrowRight className="text-3xl text-formisBlack/50" />
            </li>
            <li className="py-[5px] flex items-center justify-between">
              <p>Contact</p>
            </li>
            <li className="py-[5px] flex items-center justify-between">
              <p>Buy</p>
            </li>
            <li className="py-[5px] flex items-center justify-between">
              <p>Blogs</p>
            </li>
          </ul>

          <div
            className={`pt-4 absolute bottom-32 left-0 overflow-hidden bg-formisWhite transition-all duration-500 ease-out h-[calc(91svh-11rem)] w-full text-formisBlack ${
              expandedMobileMenu ? "translate-x-0" : "translate-x-full"
            }`}
          >
            {expandedMobileMenu === 1 && (
              <OrderHistory setExpandedMobileMenu={setExpandedMobileMenu} />
            )}
            {expandedMobileMenu === 2 && (
              <Profile setExpandedMobileMenu={setExpandedMobileMenu} />
            )}
          </div>
        </div>

        <div className="text-[14px] font-[500]">
          <div className="flex items-center justify-end px-5 gap-1 border-t border-formisGrey py-[10px]">
            <p>Delivery in</p>
            <p className="text-[16px] xl:text-xl font-bold">8 Minutes</p>
          </div>
          <div className="px-5 bg-formisGrey py-5 flex justify-between">
            <button className="bg-formisBlack text-formisPeach py-[8px] px-[20px] rounded-full flex items-center gap-3">
              <FiUser className="text-[16px]" /> Login
            </button>
            <div className="flex items-center gap-6 text-xl">
              <FaFacebookF />
              <FaXTwitter />
              <FaInstagram />
              <FaYoutube />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default MobileMain;
