import fitness from "./assets/fitness.png"
export const categories = [
    {
      img: fitness,
      title: "Health Conditions",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-03.webp?v=1708443712&width=1080",
      title: "Vitamins & Nutrition",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-04.webp?v=1708422644&width=1080",
      title: "Ayurvedic Supplements",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-05.webp?v=1708443712&width=1080",
      title: "Nutritional Drinks",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-06.webp?v=1708422644&width=1080",
      title: "Skin, hair & oral care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-07.webp?v=1708422644&width=1080",
      title: "Stomach care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-08.webp?v=1708422644&width=1080",
      title: "Sexual wellness",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-02.webp?v=1708422644&width=1080",
      title: "Wellness",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-03.webp?v=1708443712&width=1080",
      title: "Pain Relief",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-04.webp?v=1708422644&width=1080",
      title: "Skin Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-05.webp?v=1708443712&width=1080",
      title: "Feminine Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-06.webp?v=1708422644&width=1080",
      title: "Personal Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-07.webp?v=1708422644&width=1080",
      title: "Fitness Supplements",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-08.webp?v=1708422644&width=1080",
      title: "Supports & Braces",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-02.webp?v=1708422644&width=1080",
      title: "Healthcare Devices",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-03.webp?v=1708443712&width=1080",
      title: "Elderly Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-04.webp?v=1708422644&width=1080",
      title: "Diabetes",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-05.webp?v=1708443712&width=1080",
      title: "Monitoring Devices",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-06.webp?v=1708422644&width=1080",
      title: "Hair Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-07.webp?v=1708422644&width=1080",
      title: "First Aid",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-08.webp?v=1708422644&width=1080",
      title: "Baby Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-02.webp?v=1708422644&width=1080",
      title: "Protect+ Essentials",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-03.webp?v=1708443712&width=1080",
      title: "Immunity Boosters",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-04.webp?v=1708422644&width=1080",
      title: "Healthy Snacks",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-05.webp?v=1708443712&width=1080",
      title: "Homeopathy",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-06.webp?v=1708422644&width=1080",
      title: "Eye & Ear Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-07.webp?v=1708422644&width=1080",
      title: "Pet Care",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-08.webp?v=1708422644&width=1080",
      title: "Herbal Juice",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-05.webp?v=1708443712&width=1080",
      title: "Cold, Cough & Fever",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-06.webp?v=1708422644&width=1080",
      title: "Rehydration Beverages",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-07.webp?v=1708422644&width=1080",
      title: "Fever & Headache",
    },
    {
      img: "https://concept-theme-tech.myshopify.com/cdn/shop/files/collection-08.webp?v=1708422644&width=1080",
      title: "Skin Infection",
    },
  ];