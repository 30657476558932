import { useEffect, useRef, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { addToCartApi, deleteFromCartApi, searchAllApi } from "../../api";
import { IoIosArrowDown, IoIosArrowUp, IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";
import { handleWhichProduct } from "../../features/searchSlice";
import imageNA from "../../assets/Image-NA.png";

const SearchPage = ({ handlePageChange, userCart, getCartDetails }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const debouncedSearchInput = useDebounce(searchInput, 1000);
  const inputRef = useRef(null);

  const [recentSearches, setRecentSearches] = useState([]);

  const fetchSearchResults = async (searchQuery) => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await fetch(searchAllApi + searchQuery, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setSearchResults(data);
        console.log("Search:", data);
      } else {
        setSearchResults([]);
      }
    } catch {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (!searchInput) setSearchResults([]);
  }, [searchInput]);

  useEffect(() => {
    if (debouncedSearchInput) {
      fetchSearchResults(debouncedSearchInput);

      setRecentSearches((prevSearches) => {
        const updatedSearches = [debouncedSearchInput, ...prevSearches];

        return [...new Set(updatedSearches)].slice(0, 5);
      });
    }
  }, [debouncedSearchInput]);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="overflow-scroll pb-12">
      <div className="px-5 mb-4 mt-1 pt-7 md:pt-5">
        <div className="flex items-center bg-formisGrey/50 rounded-[10px] px-5 py-3">
          <input
            ref={inputRef}
            placeholder="Search For ..."
            className="bg-transparent text-sm md:text-base font-[500] w-full placeholder:text-formisBlack/60 outline-none"
            onChange={handleInputChange}
            value={searchInput}
          />
          {searchInput && (
            <button onClick={() => setSearchInput("")} className="ml-4">
              Clear
            </button>
          )}
        </div>
      </div>

      {searchInput === "" && recentSearches.length > 0 && (
        <div className="px-5">
          <h5 className="text-sm md:text-lg font-bold mb-4">Recent searches</h5>
          <div className="flex flex-wrap gap-4">
            {recentSearches.map((search, index) => (
              <div onClick={() => setSearchInput(search)}>
                <RecentSearch key={index} product={search} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-2 justify-between px-5 overflow-scroll pb-5">
        {searchInput &&
          searchResults.map((result, index) => (
            <SearchedProduct
              key={index}
              result={result}
              userCart={userCart}
              getCartDetails={getCartDetails}
              handlePageChange={handlePageChange}
            />
          ))}
      </div>
    </div>
  );
};

export default SearchPage;

//--------------------------------------------------------------------------------------------------------------------------------------------//

const RecentSearch = ({ product }) => {
  return (
    <div className="flex gap-2 items-center border border-formisGrey px-2 py-1 shadow-sm rounded-[10px]">
      <IoIosSearch className="md:text-xl" />{" "}
      <p className="text-sm md:text-base">{product}</p>
    </div>
  );
};

//--------------------------------------------------------------------------------------------------------------------------------------------//

const SearchedProduct = ({
  result,
  handlePageChange,
  userCart,
  getCartDetails,
}) => {
  const dispatch = useDispatch();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find((cartItem) => cartItem.sku_id === result.sku_id)
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === result.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    handlePageChange("product");
    dispatch(handleWhichProduct(result.slug));
  };

  // const handleDecrement = () => {
  //   if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
  //   if (cartItem?.quantity > 1) {
  //     dispatch(decrementQuantity({ sku_id: result.sku_id }));
  //   }
  // };

  // const handleIncrement = () => {
  //   dispatch(incrementQuantity({ sku_id: result.sku_id }));
  // };

  const handleAddToCart = () => {
    if (loading) return;
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(result));
  };

  const handleDecrement = () => {
    if (loading) return;
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ sku_id: result.sku_id }));
    }
  };

  const handleIncrement = () => {
    if (loading) return;
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ sku_id: res.sku_id }));
    } else dispatch(incrementQuantity({ sku_id: result.sku_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: result.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(addToCart(res));
        getCartDetails();
        // localStorage.setItem("userCart", true)
      } else {
      }
    } catch (error) {
      console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_id: result.product_id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        getCartDetails();
      } else {
      }
    } catch (error) {
      console.error("Failed to delete:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="flex gap-2">
      <div className="cursor-pointer relative max-w-[20%]">
        <img
          src={
            result.image_url !== "NA" &&
            result.image_url !==
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
              ? result.image_url
              : imageNA
          }
          className="aspect-square rounded-[10px] mb-2"
          alt={result.name}
          onClick={handleClick}
        />
        <div className="absolute top-0 left-0 text-[9px] bg-formisBlack text-formisPeach p-1 rounded-tl-[10px] rounded-br-[10px]">
          Learn More
        </div>
      </div>

      <div className="flex-1">
        <p className="font-[500] leading-none text-sm mb-1">
          {result.name.length > 18
            ? result.name.slice(0, 18) + "..."
            : result.name}
        </p>
        <p className="text-xs mb-1 capitalize">{result.packaging}</p>
        {/* <p className="text-xs mb-1 capitalize">{result.manufacturer}</p> */}
        {/* <p className="text-xs mb-1 capitalize">
          {result.salt_composition.length > 42
            ? result.salt_composition.slice(0, 42) + "..."
            : result.salt_composition}
        </p> */}
        <div className="font-[Hind]">
          <p className="text-xs font-[600]">{result.discount_percentage} OFF</p>
          <div className="flex gap-1">
            <p className="text-xs font-[600]">
              ₹{result.final_price.toFixed(2)}/-
            </p>
            <span className="text-[8px]">
              MRP <s>₹{result.mrp.toFixed(2)}/-</s>
            </span>
          </div>
        </div>
      </div>

      {isAuthenticated === "true" ? (
        <div className="flex flex-col items-end gap-2 text-xs w-[30%]">
          {/* <button className="bg-formisWhite text-formisBlack px-4 py-2 rounded-[10px] font-[500]">
          Quick Buy
        </button> */}

          {userCartItem ? (
            <div className="bg-formisWhite rounded-[10px] flex gap-2 items-center mb-3">
              <div className="pl-3 py-2" onClick={handleDecrement}>
                <IoIosArrowDown className="rotate-90" />
              </div>
              <input
                type="number"
                min="1"
                value={userCartItem?.quantity || 0}
                readOnly
                className="max-w-7 bg-transparent rounded-[5px] text-center outline-none"
              />
              <div className="pr-3 py-2" onClick={handleIncrement}>
                <IoIosArrowUp className="rotate-90" />
              </div>
            </div>
          ) : (
            <button
              className="bg-formisWhite text-formisBlack px-4 py-2 rounded-[10px] font-[500]"
              onClick={handleAddToCart}
            >
              Add to Cart
            </button>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-end gap-2 text-xs w-[30%]">
          {/* <button className="bg-formisWhite text-formisBlack px-4 py-2 rounded-[10px] font-[500]">
          Quick Buy
        </button> */}

          {cartItem ? (
            <div className="bg-formisWhite rounded-[10px] flex gap-2 items-center mb-3">
              <div className="pl-3 py-2" onClick={handleReduxDecrement}>
                <IoIosArrowDown className="rotate-90" />
              </div>
              <input
                type="number"
                min="1"
                value={cartItem?.quantity || 0}
                readOnly
                className="max-w-7 bg-transparent rounded-[5px] text-center outline-none"
              />
              <div className="pr-3 py-2" onClick={handleIncrement}>
                <IoIosArrowUp className="rotate-90" />
              </div>
            </div>
          ) : (
            <button
              className="bg-formisWhite text-formisBlack px-4 py-2 rounded-[10px] font-[500]"
              onClick={() => dispatch(addToCart(result))}
            >
              Add to Cart
            </button>
          )}
        </div>
      )}
    </div>
  );
};

//--------------------------------------------------------------------------------------------------------------------------------------------//
