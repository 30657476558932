import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { useState } from "react";
import imageNA from "../../../assets/Image-NA.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../../features/cartSlice";
import { addToCartApi, deleteFromCartApi } from "../../../api";

const ProductCard = ({ product, userCart, getCartDetails }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const maxLength = 250;

  const dispatch = useDispatch();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find((cartItem) => cartItem.sku_id === product.sku_id)
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === product.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  // const handleDecrement = () => {
  //   if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
  //   if (cartItem?.quantity > 1) {
  //     dispatch(decrementQuantity({ sku_id: product.sku_id }));
  //   }
  // };

  // const handleIncrement = () => {
  //   dispatch(incrementQuantity({ sku_id: product.sku_id }));
  // };

  const handleAddToCart = () => {
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(product));
  };

  const handleDecrement = () => {
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ sku_id: product.sku_id }));
    }
  };

  const handleIncrement = () => {
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ sku_id: product.sku_id }));
    } else dispatch(incrementQuantity({ sku_id: product.sku_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: product.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(addToCart(res));
        console.log("YO", data);
        getCartDetails();
        // localStorage.setItem("userCart", true)
      } else {
      }
    } catch (error) {
      console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_id: product.product_id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        getCartDetails();
      } else {
      }
    } catch (error) {
      console.error("Failed to delete:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <img
        src={
          product.primary_image_url !== "NA" &&
          product.primary_image_url !==
            "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
            ? product.primary_image_url
            : imageNA
        }
        alt={product.name}
        className="aspect-square rounded-[10px] max-w-[60%] mx-auto mb-5 w-full h-fit"
      />

      <div className="flex-1">
        <p className="mb-1 font-[600] tracking-wide capitalize">
          {product.packaging}
        </p>
        <h2 className="text-xl leading-7 font-[500] mb-2">{product.name}</h2>
        <div className="flex items-center justify-between mb-3">
          <div className="font-[Hind]">
            <p className="text-lg font-[600]">
              {product.discount_percentage} OFF
            </p>
            <div className="flex gap-1">
              <p className="text-lg font-[600]">
                ₹{product.final_price.toFixed(2)}/-
              </p>
              <span className="text-xs">
                MRP <s>₹{product.mrp.toFixed(2)}/-</s>
              </span>
            </div>
          </div>
        </div>

        <div>
          {/* <div>
            <p className="inline">
              <span className="font-bold">About:</span>{" "}
              <span className="font-[Hind]">
                {isExpanded
                  ? product.introduction
                  : product.introduction.length > maxLength
                  ? product.introduction.slice(0, maxLength) + "..."
                  : product.introduction}
              </span>
            </p>
            {product.introduction.length > maxLength && (
              <button
                onClick={toggleDescription}
                className="inline underline px-2"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            )}
          </div> */}

          <p>
            <span className="font-bold">Manufacturer:</span>{" "}
            <span className="font-[Hind]">{product.manufacturer}</span>
          </p>
          <p className="text-base">
            <span className="font-bold">Salt Composition/Ingredients:</span>{" "}
            <span className="font-[Hind]">
              {product.salt_composition !== "NA"
                ? product.salt_composition
                : product.ingredients}
            </span>
          </p>
        </div>

        {isAuthenticated !== "true" ? (
          <div className="flex items-center gap-4 mt-5 mb-4">
            {cartItem && (
              <div className="flex items-center border border-formisBlack/30 px-3 py-3 rounded-full w-fit">
                <div className="pr-2 text-xl cursor-pointer">
                  <IoIosArrowBack onClick={handleReduxDecrement} />
                </div>
                <input
                  type="number"
                  readOnly
                  className="w-8 text-center bg-transparent outline-none"
                  value={cartItem?.quantity || 0}
                />
                <div className="pl-2 text-xl cursor-pointer">
                  <IoIosArrowForward onClick={handleIncrement} />
                </div>
              </div>
            )}

            <button
              className="flex-1 rounded-full button-font font-[500] xl:font-[600] border-2 border-formisBlack bg-formisBlack text-formisPeach p-3 lg:p-4
                        lg:hover:bg-transparent lg:hover:text-formisBlack transition-colors duration-300"
              onClick={() => dispatch(addToCart(product))}
            >
              Add to cart
            </button>
          </div>
        ) : (
          <div className="flex items-center gap-4 mt-5 mb-4">
            {userCartItem && (
              <div className="flex items-center border border-formisBlack/30 px-3 py-3 rounded-full w-fit">
                <div className="pr-2 text-xl cursor-pointer">
                  <IoIosArrowBack onClick={handleDecrement} />
                </div>
                {!loading ? (
                  <input
                    type="number"
                    readOnly
                    className="w-8 text-center bg-transparent outline-none"
                    value={userCartItem?.quantity || 0}
                  />
                ) : (
                  <div className="w-8">
                    <div className="loader2 w-8"></div>
                  </div>
                )}
                <div className="pl-2 text-xl cursor-pointer">
                  <IoIosArrowForward onClick={handleIncrement} />
                </div>
              </div>
            )}

            <button
              className="flex-1 rounded-full button-font font-[500] xl:font-[600] border-2 border-formisBlack bg-formisBlack text-formisPeach p-3 lg:p-4
                        lg:hover:bg-transparent lg:hover:text-formisBlack transition-colors duration-300"
              onClick={handleAddToCart}
            >
              Add to cart
            </button>
          </div>
        )}

        <button
          className="p-3 lg:p-4 border-2 font-[500] xl:font-[600] border-formisBlack w-full rounded-full button-font
                            hover:bg-formisBlack hover:text-formisPeach transition-colors duration-300"
        >
          Buy it now
        </button>

        <div className="bg-formisBlack text-formisPeach px-6 py-5 rounded-[10px] mt-10">
          <div className="flex gap-2">
            <div className="bg-formisPeach h-fit text-formisBlack rounded-full">
              <TiTick />
            </div>
            <div>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                {product.storage}
              </p>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                Marketer: <b>{product.marketer_details}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
