import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { etaApi, topbarInfoApi } from "../api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Topbar = () => {
  const [info, setInfo] = useState();
  const coordinates = useSelector(
    (state) => state.googleMapLocation.coordinates
  );
  const [eta, setEta] = useState();

  const fetchETA = async () => {
    try {
      const response = await fetch(
        etaApi +
          `latitude=${coordinates.lat || 28.48218399}&longitude=${
            coordinates.lng || 77.09330629
          }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEta(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchETA();
  }, [coordinates]);

  const fetchInfo = async () => {
    try {
      const response = await fetch(topbarInfoApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setInfo(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className="bg-formisBlack text-formisPeach grid grid-cols-1 lg:grid-cols-3 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 h-12 md:h-14 text-[13px] md:text-[14px]">
      <div className="hidden lg:flex items-center gap-6 text-xl">
        <FaFacebookF />
        <FaXTwitter />
        <FaInstagram />
        <FaYoutube />
      </div>

      <div className="flex items-center justify-between gap-4">
        <HiArrowSmLeft className="text-4xl lg:text-2xl cursor-pointer prev-btn" />
        <Swiper
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          loop={true}
          className="lg:w-[30vw] text-center"
        >
          {info &&
            info.map((result) => (
              <SwiperSlide key={result.id}>
                  <p>{result.info}</p>
              </SwiperSlide>
            ))}
        </Swiper>
        <HiArrowSmRight className="text-4xl lg:text-2xl cursor-pointer next-btn" />
      </div>

      <Link to="/get-location" className="hidden lg:flex items-center justify-end gap-2">
        <TiArrowSortedDown />
        <p>Delivery in</p>
        <p className="text-[16px] xl:text-xl font-bold">
          {eta && eta.eta_minutes} minutes
        </p>
      </Link>
    </div>
  );
};

export default Topbar;
