import { BrowserRouter, Route, Routes } from "react-router-dom";
import CheckoutCart from "./pages/CheckoutCart";
import Main from "./pages/Main";
import Product from "./pages/Product";
import Categories from "./pages/Categories";
import AllProducts from "./pages/AllProducts";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import CategoryPage from "./pages/CategoryPage";
import UserLocation from "./pages/UserLocation";
import UserAddress from "./pages/UserAddress";
import Search from "./components/Search";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/cart" element={<CheckoutCart />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/login" element={<Login />} />
        <Route path="/categories/:categoryName" element={<CategoryPage />} />
        <Route path="/get-location" element={<UserLocation />} />
        <Route path="/get-address" element={<UserAddress />} />
        <Route path="/product/:slug" element={<Product />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
