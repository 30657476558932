// import { useState } from "react";

// export const useGeolocation = () => {
//   const [locationInfo, setLocationInfo] = useState(null);
//   const [locationError, setLocationError] = useState(null);

//   const getGeolocation = () => {
//     if (!navigator.geolocation) {
//       setLocationError("Geolocation is not supported by your browser.");
//       return;
//     }

//     navigator.geolocation.getCurrentPosition(
//       (res) => {
//         setLocationInfo(res.coords);
//       },
//       (err) => {
//         setLocationError(err.message);
//       }
//     );
//   };

//   return { locationInfo, locationError, getGeolocation };
// };

import { useState } from "react";

export const useGeolocation = () => {
  const [locationInfo, setLocationInfo] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGeolocation = () => {
    if (!navigator.geolocation) {
      setLocationError("Geolocation is not supported by your browser.");
      return;
    }

    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (res) => {
        setLocationInfo(res.coords);
        setLoading(false);
      },
      (err) => {
        setLocationError(err.message);
        setLoading(false);
      }
    );
  };

  return { locationInfo, locationError, loading, getGeolocation };
};
