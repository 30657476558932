const useRefresh = (handlePageChange) => {

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await fetch(
        "https://kitsa.co.in/api/v1/auth/token/refresh",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh: refreshToken,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        localStorage.setItem("refreshToken", data.refresh);
        localStorage.setItem("isAuthenticated", true)
        return true;
      } else {
        localStorage.setItem("isAuthenticated", false)
        if (handlePageChange) handlePageChange("login");
        return false;
      }
    } catch (error) {
      localStorage.setItem("isAuthenticated", false)
      if (handlePageChange) handlePageChange("login");
      return false;
    }
  };

  return { refreshAccessToken };
};

export default useRefresh;
