import { TiTick } from "react-icons/ti";
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import image from "../../assets/Image-NA.png";
import { Link } from "react-router-dom";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";

const ProductCard = ({ productData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = 250;

  const dispatch = useDispatch();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.sku_id === productData.sku_id
    )
  );
  const handleDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ sku_id: productData.sku_id }));
    }
  };
  const handleIncrement = () => {
    dispatch(incrementQuantity({ sku_id: productData.sku_id }));
  };

  return (
    <div className="lg:flex justify-between gap-10 2xl:gap-14 pr-[16px]">
      <img
        src={
          productData.primary_image_url !== "NA" &&
          productData.primary_image_url !==
            "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
            ? productData.primary_image_url
            : image
        }
        alt={productData.name}
        className="rounded-[10px] border mb-8 w-full aspect-square lg:max-w-[50%] h-fit"
      />

      <div className="flex-1 pt-[20px]">
        {/* <Link
          to="/products"
          className="flex items-center justify-start gap-2 border border-formisPeach w-fit rounded-full pl-2 pr-8  mb-5"
        >
          <FaRegArrowAltCircleLeft />
          Back
        </Link> */}

        <p className="font-medium mb-3 tracking-wide capitalize">
          {productData.packaging}
        </p>
        <h2 className="text-2xl md:text-[40px] 3xl:text-[43px] font-bold mb-5 leading-none">
          {productData.name}
        </h2>

        <div className="mb-6">
          <div className="flex flex-col md:flex-row md:gap-10 md:items-center font-[500]">
            <p className="text-base">
              MRP: <span>₹</span> {productData.mrp}
              {Number.isInteger(productData.mrp) ? ".00" : ""}/-
            </p>
            {/* <div className="flex gap-2">
              <p>Formis Price:</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
                className="text-xs h-6 py-2 px-1 flex items-center justify-center border-2 font-[500] xl:font-[600] bg-formisPeach text-formisBlack15 border-formisPeach rounded-full button-font
                      hover:bg-transparent hover:text-formisPeach transition-colors duration-300"
              >
                Claim Your Offer Today
              </a>
            </div> */}
          </div>
        </div>

        <div className="text-lg">
          {/* <p>Description: <b>{productData.description.length > 100 ? productData.description.slice(0, 100) + "..." : productData.description}</b></p> */}
          <div className="mb-5">
            <p className="inline">
              About:{" "}
              <span className="font-[Hind]">
                {isExpanded
                  ? productData.introduction
                  : productData.introduction.length > maxLength
                  ? productData.introduction.slice(0, maxLength) + "..."
                  : productData.introduction}
              </span>
            </p>
            {productData.introduction.length > maxLength && (
              <button
                onClick={toggleDescription}
                className="inline underline px-2"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            )}
          </div>

          <p className="mb-5 text-base">
            Manufacturer:{" "}
            <span className="font-[Hind]">{productData.manufacturer}</span>
          </p>
          <p className="text-base">
            Salt Composition:{" "}
            <span className="font-[Hind]">
              {productData.salt_composition !== "NA"
                ? productData.salt_composition
                : productData.ingredients}
            </span>
          </p>
        </div>

        <div className="flex items-center gap-4 mt-5 mb-4">
          {cartItem && (
            <div className="flex items-center border border-formisBlack/30 px-3 py-3 rounded-full w-fit">
              <div className="pr-2 text-xl cursor-pointer">
                <IoIosArrowBack onClick={handleDecrement} />
              </div>
              <input
                type="number"
                readOnly
                className="w-8 text-center bg-transparent outline-none"
                value={cartItem?.quantity || 0}
              />
              <div className="pl-2 text-xl cursor-pointer">
                <IoIosArrowForward onClick={handleIncrement} />
              </div>
            </div>
          )}

          <button
            className="flex-1 rounded-full button-font font-[500] xl:font-[600] border-2 border-formisBlack bg-formisBlack text-formisPeach p-3 lg:p-4
                        hover:bg-transparent hover:text-formisBlack transition-colors duration-300"
            onClick={() => dispatch(addToCart(productData))}
          >
            Add to cart
          </button>
        </div>

        <a
          className="mt-8 w-full flex items-center gap-2 justify-center p-3 lg:p-4 border-2 font-[500] xl:font-[600] bg-transparent text-formisBlack border-formisBlack rounded-full button-font
                      hover:bg-formisBlack hover:text-formisPeach transition-colors duration-300"
        >
          Buy It Now <FaWhatsapp className="text-xl" />
        </a>

        <div className="bg-formisGrey/50 px-6 py-5 rounded-[10px] mt-10">
          <div className="flex gap-2">
            <TiTick className="bg-formisBlack text-formisBlack15 rounded-full text-formisWhite" />
            <div>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                {productData.storage}
              </p>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                Marketer: <b>{productData.marketer_details}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
