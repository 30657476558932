import { HiArrowLongRight } from "react-icons/hi2";
import he from "../../assets/he.png"

const ShopMenu = () => {
  return (
    <div className="px-12 pt-10 pb-14 grid grid-cols-2 gap-x-16">
      <div className="flex flex-col justify-between">
        <div className="grid grid-cols-3 gap-y-10 gap-x-20">
          <div>
            <h4 className="text-[18px] 2xl:text-[20px] font-bold">Headphones</h4>
            <p className="2xl:text-lg">Wireless</p>
            <p className="2xl:text-lg">Gaming</p>
            <p className="2xl:text-lg">Sport</p>
            <p className="2xl:text-lg">Limited</p>
          </div>
          <div>
            <h4 className="text-[18px] 2xl:text-[20px] font-bold">Speakers</h4>
            <p className="2xl:text-lg">Portable</p>
            <p className="2xl:text-lg">Home Audio</p>
            <p className="2xl:text-lg">Speaker Sets</p>
            <p className="2xl:text-lg">Premium</p>
          </div>
          <div>
            <h4 className="text-[18px] 2xl:text-[20px] font-bold">Explore</h4>
            <p className="2xl:text-lg">Our Journal</p>
            <p className="2xl:text-lg">FAQ's</p>
            <p className="2xl:text-lg">Contact Us</p>
          </div>
          <div>
            <h4 className="text-[18px] 2xl:text-[20px] font-bold">Speakers</h4>
            <p className="2xl:text-lg">Portable</p>
            <p className="2xl:text-lg">Home Audio</p>
            <p className="2xl:text-lg">Speaker Sets</p>
            <p className="2xl:text-lg">Premium</p>
          </div>
          <div>
            <h4 className="text-[18px] 2xl:text-[20px] font-bold">Headphones</h4>
            <p className="2xl:text-lg">Wireless</p>
            <p className="2xl:text-lg">Gaming</p>
            <p className="2xl:text-lg">Sport</p>
            <p className="2xl:text-lg">Limited</p>
          </div>
        </div>

        <div>
          <hr className="border-t border-formisBlack mt-7 mb-5" />
          <div className="flex items-center justify-between">
            <h3 className="xl:text-xl 2xl:text-2xl font-bold">View All Products</h3>
            <HiArrowLongRight className="text-4xl" />
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-5">
        <div
          style={{
            backgroundImage: `url(${he})`,
          }}
          className="relative bg-cover bg-no-repeat rounded-[20px] group w-[50%]"
        >
          <div className="absolute top-0 left-0 bg-formisBlack/40 h-full w-full rounded-[20px]"></div>

          <div className="text-formisPeach relative z-10 px-5 pb-6 2xl:px-10 xl:pb-7 h-full flex items-end">
            <div className="flex justify-between w-full items-center">
              <div>
                <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                  All Products
                </h2>
                <p className="text-[12px]">Check out all our products</p>
              </div>
              <div>
                <HiArrowLongRight className="text-xl lg:text-2xl" />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${he})`,
          }}
          className="relative bg-cover bg-no-repeat rounded-[20px] group w-[50%]"
        >
          <div className="absolute top-0 left-0 bg-formisBlack/40 h-full w-full rounded-[20px]"></div>

          <div className="text-formisPeach relative z-10 px-5 pb-6 2xl:px-10 xl:pb-7 h-full flex items-end">
            <div className="flex justify-between w-full items-center">
              <div>
                <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                  All Products
                </h2>
                <p className="text-[12px]">Check out all our products</p>
              </div>
              <div>
                <HiArrowLongRight className="text-xl lg:text-2xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopMenu;
