// import { useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setCoordinates } from "../features/mapSlice";
// import customPin from "../assets/pin.png";

// const GoogleMap = () => {
//   const google = window.google;
//   const mapRef = useRef(null);
//   const markerRef = useRef(null);
//   const mapInstance = useRef(null);

//   const coordinates = useSelector(
//     (state) => state.googleMapLocation.coordinates
//   );
//   const dispatch = useDispatch();

//   useEffect(() => {
//     console.log("Coordinates: ", coordinates);
//   }, [coordinates]);

//   useEffect(() => {
//     if (mapRef.current) {
//       const mapOptions = {
//         center: { lat: 28.482183994546563, lng: 77.09330629970617 },
//         zoom: 8,
//         disableDefaultUI: true,
//         gestureHandling: "greedy",
        
//       };

//       mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);

//       // Add marker at initial coordinates
//       markerRef.current = new google.maps.Marker({
//         position: mapOptions.center,
//         map: mapInstance.current,
//         icon: {
//           url: customPin,
//           scaledSize: new google.maps.Size(30, 30),
//         },
//       });

//       // Listen for dragging and update marker's position
//       mapInstance.current.addListener("drag", () => {
//         if (markerRef.current) {
//           const center = mapInstance.current.getCenter();
//           markerRef.current.setPosition(center);
//         }
//       });

//       // Update coordinates when dragging stops
//       mapInstance.current.addListener("idle", () => {
//         const center = mapInstance.current.getCenter();
//         const lat = center.lat();
//         const lng = center.lng();

//         dispatch(
//           setCoordinates({
//             lat: lat,
//             lng: lng,
//           })
//         );
//       });
//     }
//   }, [mapRef, dispatch]);

//   useEffect(() => {
//     if (coordinates && mapInstance.current) {
//       mapInstance.current.setCenter(coordinates);
//       mapInstance.current.setZoom(15);
//       markerRef.current.setPosition(coordinates);
//     }
//   }, [coordinates]);

//   return (
//     <div id="map" ref={mapRef} className="h-[300px] md:h-[500px] w-full"></div>
//   );
// };

// export default GoogleMap;

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCoordinates } from "../features/mapSlice";
import customPin from "../assets/pin.png";

const GoogleMap = () => {
  const google = window.google;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const mapInstance = useRef(null);

  const coordinates = useSelector(
    (state) => state.googleMapLocation.coordinates
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Coordinates: ", coordinates);
  }, [coordinates]);

  useEffect(() => {
    if (mapRef.current) {
      const mapOptions = {
        center: coordinates || { lat: 28.482183994546563, lng: 77.09330629970617 },
        zoom: 15,
        disableDefaultUI: true,
        gestureHandling: "greedy",
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#686868",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                lightness: "-22",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                saturation: "11",
              },
              {
                lightness: "-51",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text",
            stylers: [
              {
                saturation: "3",
              },
              {
                lightness: "-56",
              },
              {
                weight: "2.20",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                lightness: "-52",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
              {
                weight: "6.13",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.icon",
            stylers: [
              {
                lightness: "-10",
              },
              {
                gamma: "0.94",
              },
              {
                weight: "1.24",
              },
              {
                saturation: "-100",
              },
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                lightness: "-16",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: "-41",
              },
              {
                lightness: "-41",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.stroke",
            stylers: [
              {
                weight: "5.46",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [
              {
                weight: "0.72",
              },
              {
                lightness: "-16",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                lightness: "-37",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#b7e4f4",
              },
              {
                visibility: "on",
              },
            ],
          },
        ],
        
      };

      mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);

      // Add marker at the initial coordinates
      markerRef.current = new google.maps.Marker({
        position: mapOptions.center,
        map: mapInstance.current,
        icon: {
          url: customPin,
          scaledSize: new google.maps.Size(30, 30),
        },
      });

      // Listen for dragging and update marker's position
      mapInstance.current.addListener("drag", () => {
        if (markerRef.current) {
          const center = mapInstance.current.getCenter();
          markerRef.current.setPosition(center);
        }
      });

      // Update coordinates when dragging stops
      mapInstance.current.addListener("idle", () => {
        const center = mapInstance.current.getCenter();
        const lat = center.lat();
        const lng = center.lng();

        dispatch(
          setCoordinates({
            lat: lat,
            lng: lng,
          })
        );
      });
    }
  }, [mapRef, dispatch]);

  useEffect(() => {
    if (coordinates && mapInstance.current) {
      // Only update the center of the map, leave the zoom unchanged
      mapInstance.current.panTo(coordinates);
      if (markerRef.current) {
        markerRef.current.setPosition(coordinates);
      }
    }
  }, [coordinates]);

  return (
    <div id="map" ref={mapRef} className="h-[300px] md:h-[500px] w-full"></div>
  );
};

export default GoogleMap;