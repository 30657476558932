import cartEmpty from "../../../assets/cart-empty.png"

const NothingInCart = ({ handlePageChange }) => {
  const categories = [
    "Skin, hair & oral care",
    "Stomach care",
    "Sexual wellness",
    "Feminine care",
    "Fitness supplements",
    "Vitamins & nutrition",
    "Ayurvedic supplements",
    "Nutritional drinks",
  ];

  return (
    <div className="pt-5 flex flex-col items-center justify-center h-[80%] gap-5 md:gap-8 max-w-[65%] md2:max-w-[55%] mx-auto md2:h-[80vh]">
      <h3 className="text-2xl md2:text-3xl font-bold text-center leading-none tracking-tight">
        Your cart is <br className="hidden 2xl:block" /> currently{" "}
        <br className="2xl:hidden" /> empty.
      </h3>

      {/* <div className="p-4 rounded-full  cart-shadow">
        <IoCartOutline className="text-9xl text-formisGrey" />
      </div> */}
      <img src={cartEmpty} alt="Empty Bag"/>

      <p className="leading-tight text-sm md2:text-base text-center">
        Not sure where to start?
      </p>
      <button onClick={() => handlePageChange("")} className="bg-formisBlack text-formisPeach px-4 py-2 rounded-full">Search Essentials</button>
      {/* <ul className="w-full flex flex-col gap-3">
        <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
          <span className="flex gap-3 items-center">
            <PiHeadphonesLight className="text-3xl" />
            Headphones
          </span>
          <GoArrowRight className="text-2xl" />
        </li>
        <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
          <span className="flex gap-3 items-center">
            <PiHeadphonesLight className="text-3xl" />
            Headphones
          </span>
          <GoArrowRight className="text-2xl" />
        </li>
        <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
          <span className="flex gap-3 items-center">
            <PiHeadphonesLight className="text-3xl" />
            Headphones
          </span>
          <GoArrowRight className="text-2xl" />
        </li>
        <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
          <span className="flex gap-3 items-center">
            <PiHeadphonesLight className="text-3xl" />
            Headphones
          </span>
          <GoArrowRight className="text-2xl" />
        </li>
      </ul> */}
    </div>
  );
};

export default NothingInCart;
