import { useEffect, useState } from "react";
import logo from "../assets/foressentials-logo-black.png";
import logoPeach from "../assets/foressentials-logo-peach.png";
import ShopMenu from "./Dropdown Menus/ShopMenu";
import CollectionsMenu from "./Dropdown Menus/CollectionsMenu";
import Search from "./Search";
import MobileMain from "./Mobile Menus/MobileMain";
import MobileFooter from "./MobileFooterMenu";
import Cart from "./Cart";
import { TiArrowSortedDown } from "react-icons/ti";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearchComponent } from "../features/searchSlice";
import { toggleProfileComponent } from "../features/profileSlice";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { etaApi } from "../api";
import Profile from "./Profile";

const Header = ({ transparent }) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [hovering, setHovering] = useState(null);
  const [expandedMobileMenu, setExpandedMobileMenu] = useState(null);

  const isSearchOpen = useSelector((state) => state.search.isSearchOpen);
  const isProfileOpen = useSelector((state) => state.profile.isProfileOpen);
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.cartItems);

  const coordinates = useSelector(
    (state) => state.googleMapLocation.coordinates
  );
  const [eta, setEta] = useState();

  const fetchETA = async () => {
    try {
      const response = await fetch(
        etaApi +
          `latitude=${coordinates.lat || 28.48218399}&longitude=${
            coordinates.lng || 77.09330629
          }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEta(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchETA();
  }, [coordinates]);

  useGSAP(() => {
    if (expandedMobileMenu !== null) {
      gsap.to("#mobile-menu-list", {
        translateX: "-100px",
        duration: 0.25,
      });
    }
    if (expandedMobileMenu == null) {
      gsap.to("#mobile-menu-list", {
        translateX: 0,
      });
    }
  }, [expandedMobileMenu]);

  useGSAP(() => {
    if (isMenuOpen) {
      gsap.fromTo(
        "#mobile-menu-list li",
        {
          translateX: "-80px",
          opacity: 0,
        },
        {
          translateX: 0,
          opacity: 1,
          stagger: 0.1,
          duration: 0.25,
          ease: "power2.out",
        }
      );
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setExpandedMobileMenu(null);
  };

  const toggleSearch = () => {
    dispatch(toggleSearchComponent());
  };

  const toggleProfile = () => {
    dispatch(toggleProfileComponent());
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  useEffect(() => {
    if (isMenuOpen || isSearchOpen || isCartOpen || isProfileOpen || hovering) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen, isSearchOpen, isCartOpen, hovering, isProfileOpen]);

  return (
    <header
      className={`relative px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12 py-6 md:py-8 ${
        transparent && !hovering
          ? "bg-transparent text-formisPeach"
          : "bg-formisWhite text-formisBlack"
      } transition-all duration-500 ${
        hovering ? "rounded-none" : "rounded-t-[20px]"
      }`}
      onMouseLeave={() => setHovering(null)}
    >
      <div
        className={`grid grid-cols-2 lg:grid-cols-4 items-center w-full px-2 lg:px-0`}
      >
        <div className="flex items-center gap-5">
          <Link to="/">
            <img
              src={transparent && !hovering ? logoPeach : logo}
              alt="FORMIS"
              className="max-w-[160px] md:max-w-[180px]"
            />
          </Link>
        </div>

        {/* Menu Items */}
        <ul className="hidden lg:flex justify-center font-[500] gap-2 text-[15px] xl:text-[17px] col-span-2">
          <li
            className={`${
              hovering === 1
                ? "px-4 py-2 rounded-full bg-formisBlack text-formisPeach transition-colors cursor-pointer"
                : "header-menu"
            }`}
            onMouseEnter={() => setHovering(1)}
            onClick={() => navigate("/all-products")}
          >
            Shop
          </li>
          <li
            className={`${
              hovering === 2
                ? "px-4 py-2 rounded-full bg-formisBlack text-formisPeach transition-colors cursor-pointer"
                : "header-menu"
            }`}
            onMouseEnter={() => setHovering(2)}
            onClick={() => navigate("/categories")}
          >
            Collections
          </li>
          <li className="header-menu" onMouseEnter={() => setHovering(false)}>
            Contact
          </li>
          <li className="header-menu" onMouseEnter={() => setHovering(false)}>
            Buy
          </li>
          <li className="header-menu" onMouseEnter={() => setHovering(false)}>
            Blogs
          </li>
        </ul>

        {/* Hovered Mega Menu */}
        <div
          className={`${
            hovering ? "bg-formisBlack/50 z-[20]" : "bg-transparent -z-[50]"
          } absolute top-[6rem] left-0 w-screen h-screen`}
          onMouseEnter={() => setHovering(null)}
        />
        <div
          className={`absolute top-[6rem] left-0 w-full z-[101] overflow-hidden bg-formisWhite transition-all duration-1000 ease-out rounded-b-[20px] ${
            hovering ? "max-h-[60vh] xl:max-h-[80vh]" : "max-h-0"
          }`}
        >
          <div
            className={`transition-opacity duration-500 ${
              hovering === 1 ? "opacity-100" : "opacity-0 max-h-0"
            }`}
          >
            <ShopMenu />
          </div>
          <div
            className={`transition-opacity duration-500 ${
              hovering === 2 ? "opacity-100" : "opacity-0 max-h-0"
            }`}
          >
            <CollectionsMenu />
          </div>
        </div>

        {/* Icons */}
        <div
          className="flex items-center justify-end text-2xl gap-4 lg:gap-7 w-fit place-self-end"
          onMouseEnter={() => setHovering(false)}
        >
          <Link to="/get-location" className="flex items-center gap-1">
            <TiArrowSortedDown className="lg:hidden text-xl" />
            <div className="lg:hidden flex flex-col items-end justify-end leading-none col-span-2">
              <p className="text-sm">Delivery in</p>
              <p className="text-[16px] font-bold">
                {eta && eta.eta_minutes} minutes
              </p>
            </div>
          </Link>

          <svg
            className="w-6 hidden lg:block cursor-pointer"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleSearch}
          >
            <path
              strokeLinecap="round"
              d="m21 21-3.636-3.636m0 0A9 9 0 1 0 4.636 4.636a9 9 0 0 0 12.728 12.728Z"
            ></path>
          </svg>

          <svg
            className="w-6 cursor-pointer hidden lg:block"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={toggleProfile}
          >
            <rect width="10.5" height="10.5" x="6.75" y="1.75" rx="5.25"></rect>
            <path
              strokeLinecap="round"
              d="M12 15.5c1.5 0 4 .333 4.5.5.5.167 3.7.8 4.5 2 1 1.5 1 2 1 4m-10-6.5c-1.5 0-4 .333-4.5.5-.5.167-3.7.8-4.5 2-1 1.5-1 2-1 4"
            ></path>
          </svg>

          <div className=" hidden lg:block relative">
            {items.length !== 0 && (
              <span className="absolute -right-2 -top-5 text-[12px] font-semibold">
                {items.length}
              </span>
            )}
            <svg
              className="w-6 cursor-pointer"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleCart}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M1 1h.5v0c.226 0 .339 0 .44.007a3 3 0 0 1 2.62 1.976c.034.095.065.204.127.42l.17.597m0 0 1.817 6.358c.475 1.664.713 2.496 1.198 3.114a4 4 0 0 0 1.633 1.231c.727.297 1.592.297 3.322.297h2.285c1.75 0 2.626 0 3.359-.302a4 4 0 0 0 1.64-1.253c.484-.627.715-1.472 1.175-3.161l.06-.221c.563-2.061.844-3.092.605-3.906a3 3 0 0 0-1.308-1.713C19.92 4 18.853 4 16.716 4H4.857ZM12 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm8 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <MobileMain
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        expandedMobileMenu={expandedMobileMenu}
        setExpandedMobileMenu={setExpandedMobileMenu}
      />
      <Search isSearchOpen={isSearchOpen} toggleSearch={toggleSearch} />
      <Cart isCartOpen={isCartOpen} toggleCart={toggleCart} />
      <MobileFooter
        toggleMenu={toggleMenu}
        toggleSearch={toggleSearch}
        toggleCart={toggleCart}
        toggleProfile={toggleProfile}
      />
      <Profile
        isProfileOpen={isProfileOpen}
        toggleProfile={toggleProfile}
      />
    </header>
  );
};

export default Header;
