// import { BiSolidNotepad } from "react-icons/bi";
// import { MdDeliveryDining } from "react-icons/md";
// import { FaBagShopping } from "react-icons/fa6";
// import { GiPlatform } from "react-icons/gi";
// import { useSelector } from "react-redux";

// const CartDetails = ({ userCart }) => {
//   const isAuthenticated = localStorage.getItem("isAuthenticated")

//   const cartItems = useSelector((state) => state.cartItems.items);
//   const totalPrice = cartItems.reduce((total, item) => {
//     return total + item.quantity * item.mrp;
//   }, 0);

//   return (
//     <div className="bg-formisWhite p-3 rounded-[20px]">
//       <p className="text-lg font-semibold mb-2">Bill Details</p>

//       <div className="text-sm border-b border-formisGrey pb-3">
//         <div className="flex items-center justify-between">
//           <div className="flex items-center justify-between gap-1">
//             <BiSolidNotepad />
//             <p>Items Total</p>
//           </div>
//           <div>
//             <p>₹{totalPrice.toFixed(2)}/-</p>
//             {/* {isAuthenticated === 'true' ? <p>₹{userTotalPrice}/-</p> : <p>₹{totalPrice.toFixed(2)}/-</p>} */}
//           </div>
//         </div>
//         <div className="flex items-center justify-between">
//           <div className="flex items-center justify-between gap-1">
//             <MdDeliveryDining />
//             <p>Delivery Charge</p>
//           </div>
//           <div>
//             <p>FREE</p>
//           </div>
//         </div>
//         <div className="flex items-center justify-between">
//           <div className="flex items-center justify-between gap-1">
//             <FaBagShopping />
//             <p>Handling Charge</p>
//           </div>
//           <div>
//             <p>FREE</p>
//           </div>
//         </div>
//         <div className="flex items-center justify-between">
//           <div className="flex items-center justify-between gap-1">
//             <GiPlatform />
//             <p>Platform Fee</p>
//           </div>
//           <div>
//             <p>FREE</p>
//           </div>
//         </div>
//       </div>

//       <div className="pt-2 font-[500] flex items-center justify-between">
//         <p>Grand Total</p>
//         <p>₹{totalPrice.toFixed(2)}/-</p>
//       </div>
//     </div>
//   );
// };

// export default CartDetails;

import { BiSolidNotepad } from "react-icons/bi";
import { MdDeliveryDining } from "react-icons/md";
import { FaBagShopping } from "react-icons/fa6";
import { GiPlatform } from "react-icons/gi";
import { useSelector } from "react-redux";
import { RiDiscountPercentFill } from "react-icons/ri";

const CartDetails = ({ userCart }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  // Always call useSelector at the top level
  const cartItems = useSelector((state) => state.cartItems.items);

  // Determine which cart to use based on authentication
  const activeCart = isAuthenticated ? userCart : cartItems;

  // Calculate total price
  const totalPrice = activeCart.reduce((total, item) => {
    return total + item.quantity * item.selling_price_at_time || item.quantity * item.final_price;
  }, 0);

  const totalMrp = activeCart.reduce((total, item) => {
    return total + item.quantity * item.mrp;
  }, 0);

  const discountAmount = activeCart.reduce((total, item) => {
    return (
      total + item.quantity * item.discount_amount || item.quantity * item.discounted_amount
    );
  }, 0);

  return (
    <div className="bg-formisWhite p-3 rounded-[20px]">
      <p className="text-lg font-semibold mb-2">Bill Details</p>

      <div className="text-sm border-b border-formisGrey pb-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-1">
            <BiSolidNotepad />
            <p>Items Total</p>
          </div>
          <div>
            <p>₹{totalMrp.toFixed(2)}/-</p>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-1">
            <RiDiscountPercentFill />
            <p>Discount</p>
          </div>
          <div>
            <p>₹{discountAmount.toFixed(2)}/-</p>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-1">
            <MdDeliveryDining />
            <p>Delivery Charge</p>
          </div>
          <div>
            <p>FREE</p>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-1">
            <FaBagShopping />
            <p>Handling Charge</p>
          </div>
          <div>
            <p>FREE</p>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-1">
            <GiPlatform />
            <p>Platform Fee</p>
          </div>
          <div>
            <p>FREE</p>
          </div>
        </div>
      </div>

      <div className="pt-2 font-[500] flex items-center justify-between">
        <p>Grand Total</p>
        <p>₹{totalPrice.toFixed(2)}/-</p>
      </div>
    </div>
  );
};

export default CartDetails;
