import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";

const Floater = () => {
  return (
    <div className="hidden bg-white/80 border border-formisBlack/30 backdrop-blur-md rounded-full -rotate-90 md2:flex items-center fixed top-[40%] scale-75 md2:scale-100 -left-6 md2:-left-16 py-[0.65rem] px-3 z-[100]">
      <div className="md2:mr-4">
        <div className="bg-formisGrey py-2 px-3 rounded-full">
          <p className="text-[10px] tracking-wide">GET 20% OFF</p>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <FaFacebookF className="rotate-90 text-xl" />
        <FaYoutube className="rotate-90 text-xl" />
        <FaXTwitter className="rotate-90 text-xl" />
        <FaInstagram className="rotate-90 text-xl" />
      </div>
    </div>
  );
};

export default Floater;
