import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useState } from "react";

const Services = ({isSearchPage}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleIndexChange = (swiper) => {
    setCurrentIndex(swiper.realIndex);
  };

  return (
    <div className="bg-formisBlack">
      <div className={`${isSearchPage ? "visible bg-white rounded-b-none" : " rounded-b-[15px] lg:hidden"} bg-formisWhite py-11 border-t border-formisBlack/30`}>
        <Swiper
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          loop={true}
          onSlideChange={handleIndexChange}
        >
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-6 mb-4"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.09 17.43H4.75C3.65 17.43 2.75 16.54 2.75 15.43V12.28C2.75 11.18 3.64 10.28 4.75 10.28H6.09C7.19 10.28 8.09 11.17 8.09 12.28V15.43C8.09 16.53 7.2 17.43 6.09 17.43ZM20.56 15.43V12.28C20.56 11.18 19.67 10.28 18.56 10.28H17.22C16.12 10.28 15.22 11.17 15.22 12.28V15.43C15.22 16.53 16.11 17.43 17.22 17.43H18.56C19.66 17.43 20.56 16.54 20.56 15.43Z"></path>
                <path
                  d="M4.94 17.43V19.86C4.94 20.93 6.01 22 7.43 22H11.65M20.56 13.78V10.67C20.57 5.74 16.58 1.75 11.66 1.75C6.74 1.75 2.75 5.74 2.75 10.66V13.77M12.27 21.99C12.27 22.32 12 22.59 11.67 22.59C11.34 22.59 11.07 22.32 11.07 21.99M12.27 21.99C12.27 21.66 12 21.39 11.67 21.39C11.34 21.39 11.07 21.66 11.07 21.99M12.27 21.99H11.07M20.57 14.76V12.96C20.57 12.13 20.57 11.71 20.43 11.39C20.25 10.95 19.9 10.61 19.47 10.43C19.14 10.29 18.73 10.29 17.9 10.29C17.07 10.29 16.65 10.29 16.33 10.43C15.89 10.61 15.55 10.96 15.37 11.39C15.23 11.72 15.23 12.13 15.23 12.96V14.76C15.23 15.59 15.23 16.01 15.37 16.33C15.55 16.77 15.9 17.11 16.33 17.29C16.66 17.43 17.07 17.43 17.9 17.43C18.73 17.43 19.15 17.43 19.47 17.29C19.91 17.11 20.25 16.76 20.43 16.33C20.57 16 20.57 15.59 20.57 14.76ZM5.43 17.43C6.26 17.43 6.68 17.43 7 17.29C7.44 17.11 7.78 16.76 7.96 16.33C8.1 16 8.1 15.59 8.1 14.76V12.96C8.1 12.13 8.1 11.71 7.96 11.39C7.78 10.95 7.43 10.61 7 10.43C6.67 10.29 6.26 10.29 5.43 10.29C4.6 10.29 4.18 10.29 3.86 10.43C3.42 10.61 3.08 10.96 2.9 11.39C2.76 11.72 2.76 12.13 2.76 12.96V14.76C2.76 15.59 2.76 16.01 2.9 16.33C3.08 16.77 3.43 17.11 3.86 17.29C4.19 17.43 4.6 17.43 5.43 17.43Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <h6 className="text-lg font-semibold mb-2">Customer service</h6>
              <p className="text-sm">
                It's not actually free we just price it into the products.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-6 mb-4"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M22 14.2825V7.28202C22 7.10718 21.9083 6.94515 21.7583 6.8552C21.5995 6.75991 21.4009 6.76067 21.2429 6.85718L14.5012 10.9731C13.5927 11.5278 13.1384 11.8052 12.6525 11.9135C12.2228 12.0092 11.7772 12.0092 11.3475 11.9135C10.8616 11.8052 10.4073 11.5278 9.49878 10.9731L2.75714 6.85718C2.59906 6.76067 2.40048 6.75991 2.24166 6.8552C2.09174 6.94515 2 7.10718 2 7.28202V14.2825C2 15.2735 2 15.769 2.14219 16.2143C2.26802 16.6083 2.47396 16.972 2.74708 17.2826C3.05572 17.6336 3.48062 17.8886 4.33042 18.3984L9.53042 21.5184C10.4283 22.0572 10.8773 22.3266 11.3565 22.4318C11.7805 22.5249 12.2195 22.5249 12.6435 22.4318C13.1227 22.3266 13.5717 22.0572 14.4696 21.5184L19.6696 18.3984C20.5194 17.8886 20.9443 17.6336 21.2529 17.2826C21.526 16.972 21.732 16.6083 21.8578 16.2143C22 15.769 22 15.2735 22 14.2825Z"></path>
                <path
                  d="M7.49988 9.5L16.5 4M12 12.5L21 7M12 12.5L3 7M12 12.5V22.5M2 9.71771V14.2823C2 15.2733 2 15.7688 2.14219 16.2141C2.26802 16.6081 2.47396 16.9718 2.74708 17.2824C3.05572 17.6334 3.48062 17.8884 4.33042 18.3983L9.53042 21.5183C10.4283 22.057 10.8773 22.3264 11.3565 22.4316C11.7805 22.5247 12.2195 22.5247 12.6435 22.4316C13.1227 22.3264 13.5717 22.057 14.4696 21.5183L19.6696 18.3983C20.5194 17.8884 20.9443 17.6334 21.2529 17.2824C21.526 16.9718 21.732 16.6081 21.8578 16.2141C22 15.7688 22 15.2733 22 14.2823V9.71771C22 8.72669 22 8.23117 21.8578 7.78593C21.732 7.39192 21.526 7.02818 21.2529 6.71757C20.9443 6.36657 20.5194 6.11163 19.6696 5.60175L14.4696 2.48175C13.5717 1.94301 13.1227 1.67364 12.6435 1.56839C12.2195 1.4753 11.7805 1.4753 11.3565 1.56839C10.8773 1.67364 10.4283 1.94301 9.53042 2.48175L4.33042 5.60175C3.48062 6.11163 3.05572 6.36657 2.74708 6.71757C2.47396 7.02818 2.26802 7.39192 2.14219 7.78593C2 8.23117 2 8.72669 2 9.71771Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <h6 className="text-lg font-semibold mb-2">Fast free shipping</h6>
              <p className="text-sm">
                Get free shipping on orders or $150 or more.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-6 mb-4"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M1 18.8C1 16.149 3.14903 14 5.8 14H10.2C12.851 14 15 16.149 15 18.8C15 20.5673 13.5673 22 11.8 22H4.2C2.43269 22 1 20.5673 1 18.8Z"></path>
                  <path d="M12 6.00001C12 8.20915 10.2091 10 8 10C5.79086 10 4 8.20915 4 6.00001C4 3.79087 5.79086 2.00001 8 2.00001C10.2091 2.00001 12 3.79087 12 6.00001Z"></path>
                </g>
                <path
                  d="M15 10C17.2091 10 19 8.20915 19 6.00001C19 3.79087 17.2091 2.00001 15 2.00001M17 22H19.8C21.5673 22 23 20.5673 23 18.8V18.8C23 16.149 20.851 14 18.2 14H17M12 6.00001C12 8.20915 10.2091 10 8 10C5.79086 10 4 8.20915 4 6.00001C4 3.79087 5.79086 2.00001 8 2.00001C10.2091 2.00001 12 3.79087 12 6.00001ZM4.2 22H11.8C13.5673 22 15 20.5673 15 18.8V18.8C15 16.149 12.851 14 10.2 14H5.8C3.14903 14 1 16.149 1 18.8V18.8C1 20.5673 2.43269 22 4.2 22Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <h6 className="text-lg font-semibold mb-2">Refer a friend</h6>
              <p className="text-sm">
                Get free shipping on orders or $150 or more.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col items-center justify-center">
              <svg
                className="w-6 mb-4"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 8.11938C3 6.99027 3 6.42571 3.17756 5.93283C3.33451 5.49716 3.59019 5.10373 3.92457 4.78337C4.30286 4.42093 4.81875 4.19165 5.85055 3.73308L9.40073 2.15523C10.3579 1.72982 10.8365 1.51712 11.334 1.43311C11.7749 1.35866 12.2251 1.35866 12.666 1.43311C13.1635 1.51712 13.6421 1.72982 14.5993 2.15523L18.1495 3.73308C19.1812 4.19165 19.6971 4.42093 20.0754 4.78337C20.4098 5.10373 20.6655 5.49716 20.8224 5.93283C21 6.42571 21 6.99027 21 8.11938V13.5748C21 14.8271 21 15.4532 20.8274 16.0216C20.6746 16.5247 20.4241 16.9928 20.0902 17.3991C19.713 17.858 19.192 18.2053 18.1501 18.8999L14.6626 21.2249C13.7003 21.8665 13.2192 22.1872 12.6991 22.3118C12.2395 22.422 11.7605 22.422 11.3009 22.3118C10.7808 22.1872 10.2997 21.8665 9.33744 21.2249L5.84992 18.8999C4.80796 18.2053 4.28697 17.858 3.90982 17.3991C3.57592 16.9928 3.32541 16.5247 3.1726 16.0216C3 15.4532 3 14.8271 3 13.5748V8.11938Z"></path>
                <path
                  d="M9 12L11 14L15.5 9.5M9.40073 2.15523L5.85055 3.73308C4.81875 4.19165 4.30286 4.42093 3.92457 4.78337C3.59019 5.10373 3.33451 5.49716 3.17756 5.93283C3 6.42571 3 6.99027 3 8.11938V13.5748C3 14.8271 3 15.4532 3.1726 16.0216C3.32541 16.5247 3.57592 16.9928 3.90982 17.3991C4.28697 17.858 4.80796 18.2053 5.84992 18.8999L9.33744 21.2249C10.2997 21.8665 10.7808 22.1872 11.3009 22.3118C11.7605 22.422 12.2395 22.422 12.6991 22.3118C13.2192 22.1872 13.7003 21.8665 14.6626 21.2249L18.1501 18.8999C19.192 18.2053 19.713 17.858 20.0902 17.3991C20.4241 16.9928 20.6746 16.5247 20.8274 16.0216C21 15.4532 21 14.8271 21 13.5748V8.11938C21 6.99027 21 6.42571 20.8224 5.93283C20.6655 5.49716 20.4098 5.10373 20.0754 4.78337C19.6971 4.42093 19.1812 4.19165 18.1495 3.73308L14.5993 2.15523C13.6421 1.72982 13.1635 1.51712 12.666 1.43311C12.2251 1.35866 11.7749 1.35866 11.334 1.43311C10.8365 1.51712 10.3579 1.72982 9.40073 2.15523Z"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <h6 className="text-lg font-semibold mb-2">Secure payment</h6>
              <p className="text-sm">
                Get free shipping on orders of $150 or more.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="flex gap-4 items-center justify-center mt-7 h-[8px]">
          <div
            onClick={() => setCurrentIndex(0)}
            className={`${
              currentIndex === 0
                ? "border-2 border-formisBlack bg-formisPeach size-[8px]"
                : "bg-formisBlack border border-transparent size-[5px]"
            } rounded-full transition-all duration-300`}
          />
          <div
            onClick={() => setCurrentIndex(1)}
            className={`${
              currentIndex === 1
                ? "border-2 border-formisBlack bg-formisPeach size-[8px]"
                : "bg-formisBlack border border-transparent size-[5px]"
            } rounded-full transition-all duration-300`}
          />
          <div
            onClick={() => setCurrentIndex(2)}
            className={`${
              currentIndex === 2
                ? "border-2 border-formisBlack bg-formisPeach size-[8px]"
                : "bg-formisBlack border border-transparent size-[5px]"
            } rounded-full transition-all duration-300`}
          />
          <div
            onClick={() => setCurrentIndex(3)}
            className={`${
              currentIndex === 3
                ? "border-2 border-formisBlack bg-formisPeach size-[8px]"
                : "bg-formisBlack border border-transparent size-[5px]"
            } rounded-full transition-all duration-300`}
          />
        </div>
      </div>

      <div className={`${isSearchPage ? "lg:hidden" : "lg:flex"} hidden justify-between bg-formisWhite py-16 rounded-b-[15px] border-t border-formisBlack/30 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12`}>
        <div className="flex flex-col items-center justify-center">
          <svg
            className="w-6 mb-4"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.09 17.43H4.75C3.65 17.43 2.75 16.54 2.75 15.43V12.28C2.75 11.18 3.64 10.28 4.75 10.28H6.09C7.19 10.28 8.09 11.17 8.09 12.28V15.43C8.09 16.53 7.2 17.43 6.09 17.43ZM20.56 15.43V12.28C20.56 11.18 19.67 10.28 18.56 10.28H17.22C16.12 10.28 15.22 11.17 15.22 12.28V15.43C15.22 16.53 16.11 17.43 17.22 17.43H18.56C19.66 17.43 20.56 16.54 20.56 15.43Z"></path>
            <path
              d="M4.94 17.43V19.86C4.94 20.93 6.01 22 7.43 22H11.65M20.56 13.78V10.67C20.57 5.74 16.58 1.75 11.66 1.75C6.74 1.75 2.75 5.74 2.75 10.66V13.77M12.27 21.99C12.27 22.32 12 22.59 11.67 22.59C11.34 22.59 11.07 22.32 11.07 21.99M12.27 21.99C12.27 21.66 12 21.39 11.67 21.39C11.34 21.39 11.07 21.66 11.07 21.99M12.27 21.99H11.07M20.57 14.76V12.96C20.57 12.13 20.57 11.71 20.43 11.39C20.25 10.95 19.9 10.61 19.47 10.43C19.14 10.29 18.73 10.29 17.9 10.29C17.07 10.29 16.65 10.29 16.33 10.43C15.89 10.61 15.55 10.96 15.37 11.39C15.23 11.72 15.23 12.13 15.23 12.96V14.76C15.23 15.59 15.23 16.01 15.37 16.33C15.55 16.77 15.9 17.11 16.33 17.29C16.66 17.43 17.07 17.43 17.9 17.43C18.73 17.43 19.15 17.43 19.47 17.29C19.91 17.11 20.25 16.76 20.43 16.33C20.57 16 20.57 15.59 20.57 14.76ZM5.43 17.43C6.26 17.43 6.68 17.43 7 17.29C7.44 17.11 7.78 16.76 7.96 16.33C8.1 16 8.1 15.59 8.1 14.76V12.96C8.1 12.13 8.1 11.71 7.96 11.39C7.78 10.95 7.43 10.61 7 10.43C6.67 10.29 6.26 10.29 5.43 10.29C4.6 10.29 4.18 10.29 3.86 10.43C3.42 10.61 3.08 10.96 2.9 11.39C2.76 11.72 2.76 12.13 2.76 12.96V14.76C2.76 15.59 2.76 16.01 2.9 16.33C3.08 16.77 3.43 17.11 3.86 17.29C4.19 17.43 4.6 17.43 5.43 17.43Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <h6 className="text-lg font-semibold mb-2">Customer service</h6>
          <p className="text-sm text-center">
            It's not actually free we just price it into the products.
          </p>
        </div>

        <div className="border-l border-formisBlack/30 mx-5" />

        <div className="flex flex-col items-center justify-center">
          <svg
            className="w-6 mb-4"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22 14.2825V7.28202C22 7.10718 21.9083 6.94515 21.7583 6.8552C21.5995 6.75991 21.4009 6.76067 21.2429 6.85718L14.5012 10.9731C13.5927 11.5278 13.1384 11.8052 12.6525 11.9135C12.2228 12.0092 11.7772 12.0092 11.3475 11.9135C10.8616 11.8052 10.4073 11.5278 9.49878 10.9731L2.75714 6.85718C2.59906 6.76067 2.40048 6.75991 2.24166 6.8552C2.09174 6.94515 2 7.10718 2 7.28202V14.2825C2 15.2735 2 15.769 2.14219 16.2143C2.26802 16.6083 2.47396 16.972 2.74708 17.2826C3.05572 17.6336 3.48062 17.8886 4.33042 18.3984L9.53042 21.5184C10.4283 22.0572 10.8773 22.3266 11.3565 22.4318C11.7805 22.5249 12.2195 22.5249 12.6435 22.4318C13.1227 22.3266 13.5717 22.0572 14.4696 21.5184L19.6696 18.3984C20.5194 17.8886 20.9443 17.6336 21.2529 17.2826C21.526 16.972 21.732 16.6083 21.8578 16.2143C22 15.769 22 15.2735 22 14.2825Z"></path>
            <path
              d="M7.49988 9.5L16.5 4M12 12.5L21 7M12 12.5L3 7M12 12.5V22.5M2 9.71771V14.2823C2 15.2733 2 15.7688 2.14219 16.2141C2.26802 16.6081 2.47396 16.9718 2.74708 17.2824C3.05572 17.6334 3.48062 17.8884 4.33042 18.3983L9.53042 21.5183C10.4283 22.057 10.8773 22.3264 11.3565 22.4316C11.7805 22.5247 12.2195 22.5247 12.6435 22.4316C13.1227 22.3264 13.5717 22.057 14.4696 21.5183L19.6696 18.3983C20.5194 17.8884 20.9443 17.6334 21.2529 17.2824C21.526 16.9718 21.732 16.6081 21.8578 16.2141C22 15.7688 22 15.2733 22 14.2823V9.71771C22 8.72669 22 8.23117 21.8578 7.78593C21.732 7.39192 21.526 7.02818 21.2529 6.71757C20.9443 6.36657 20.5194 6.11163 19.6696 5.60175L14.4696 2.48175C13.5717 1.94301 13.1227 1.67364 12.6435 1.56839C12.2195 1.4753 11.7805 1.4753 11.3565 1.56839C10.8773 1.67364 10.4283 1.94301 9.53042 2.48175L4.33042 5.60175C3.48062 6.11163 3.05572 6.36657 2.74708 6.71757C2.47396 7.02818 2.26802 7.39192 2.14219 7.78593C2 8.23117 2 8.72669 2 9.71771Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <h6 className="text-lg font-semibold mb-2">Fast free shipping</h6>
          <p className="text-sm text-center">
            Get free shipping on orders or $150 or more.
          </p>
        </div>

        <div className="border-l border-formisBlack/30 mx-5" />

        <div className="flex flex-col items-center justify-center">
          <svg
            className="w-6 mb-4"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="M1 18.8C1 16.149 3.14903 14 5.8 14H10.2C12.851 14 15 16.149 15 18.8C15 20.5673 13.5673 22 11.8 22H4.2C2.43269 22 1 20.5673 1 18.8Z"></path>
              <path d="M12 6.00001C12 8.20915 10.2091 10 8 10C5.79086 10 4 8.20915 4 6.00001C4 3.79087 5.79086 2.00001 8 2.00001C10.2091 2.00001 12 3.79087 12 6.00001Z"></path>
            </g>
            <path
              d="M15 10C17.2091 10 19 8.20915 19 6.00001C19 3.79087 17.2091 2.00001 15 2.00001M17 22H19.8C21.5673 22 23 20.5673 23 18.8V18.8C23 16.149 20.851 14 18.2 14H17M12 6.00001C12 8.20915 10.2091 10 8 10C5.79086 10 4 8.20915 4 6.00001C4 3.79087 5.79086 2.00001 8 2.00001C10.2091 2.00001 12 3.79087 12 6.00001ZM4.2 22H11.8C13.5673 22 15 20.5673 15 18.8V18.8C15 16.149 12.851 14 10.2 14H5.8C3.14903 14 1 16.149 1 18.8V18.8C1 20.5673 2.43269 22 4.2 22Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <h6 className="text-lg font-semibold mb-2">Refer a friend</h6>
          <p className="text-sm text-center">
            Get free shipping on orders or $150 or more.
          </p>
        </div>

        <div className="border-l border-formisBlack/30 mx-5" />

        <div className="flex flex-col items-center justify-center">
          <svg
            className="w-6 mb-4"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 8.11938C3 6.99027 3 6.42571 3.17756 5.93283C3.33451 5.49716 3.59019 5.10373 3.92457 4.78337C4.30286 4.42093 4.81875 4.19165 5.85055 3.73308L9.40073 2.15523C10.3579 1.72982 10.8365 1.51712 11.334 1.43311C11.7749 1.35866 12.2251 1.35866 12.666 1.43311C13.1635 1.51712 13.6421 1.72982 14.5993 2.15523L18.1495 3.73308C19.1812 4.19165 19.6971 4.42093 20.0754 4.78337C20.4098 5.10373 20.6655 5.49716 20.8224 5.93283C21 6.42571 21 6.99027 21 8.11938V13.5748C21 14.8271 21 15.4532 20.8274 16.0216C20.6746 16.5247 20.4241 16.9928 20.0902 17.3991C19.713 17.858 19.192 18.2053 18.1501 18.8999L14.6626 21.2249C13.7003 21.8665 13.2192 22.1872 12.6991 22.3118C12.2395 22.422 11.7605 22.422 11.3009 22.3118C10.7808 22.1872 10.2997 21.8665 9.33744 21.2249L5.84992 18.8999C4.80796 18.2053 4.28697 17.858 3.90982 17.3991C3.57592 16.9928 3.32541 16.5247 3.1726 16.0216C3 15.4532 3 14.8271 3 13.5748V8.11938Z"></path>
            <path
              d="M9 12L11 14L15.5 9.5M9.40073 2.15523L5.85055 3.73308C4.81875 4.19165 4.30286 4.42093 3.92457 4.78337C3.59019 5.10373 3.33451 5.49716 3.17756 5.93283C3 6.42571 3 6.99027 3 8.11938V13.5748C3 14.8271 3 15.4532 3.1726 16.0216C3.32541 16.5247 3.57592 16.9928 3.90982 17.3991C4.28697 17.858 4.80796 18.2053 5.84992 18.8999L9.33744 21.2249C10.2997 21.8665 10.7808 22.1872 11.3009 22.3118C11.7605 22.422 12.2395 22.422 12.6991 22.3118C13.2192 22.1872 13.7003 21.8665 14.6626 21.2249L18.1501 18.8999C19.192 18.2053 19.713 17.858 20.0902 17.3991C20.4241 16.9928 20.6746 16.5247 20.8274 16.0216C21 15.4532 21 14.8271 21 13.5748V8.11938C21 6.99027 21 6.42571 20.8224 5.93283C20.6655 5.49716 20.4098 5.10373 20.0754 4.78337C19.6971 4.42093 19.1812 4.19165 18.1495 3.73308L14.5993 2.15523C13.6421 1.72982 13.1635 1.51712 12.666 1.43311C12.2251 1.35866 11.7749 1.35866 11.334 1.43311C10.8365 1.51712 10.3579 1.72982 9.40073 2.15523Z"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <h6 className="text-lg font-semibold mb-2">Secure payment</h6>
          <p className="text-sm text-center">
            Get free shipping on orders or $150 or more.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
