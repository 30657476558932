import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const OrderHistory = ({ setExpandedMobileMenu }) => {
  return (
    <div>
      <button
        onClick={() => setExpandedMobileMenu(null)}
        className="flex items-center text-formisBlack/30"
      >
        <MdOutlineKeyboardArrowLeft className="text-3xl" />
        <p className="font-bold text-lg">Order History</p>
      </button>
      <hr className="border-t border-formisBlack/10 my-3" />
      <ul className="text-[24px] font-bold px-5">
        <li className="py-[5px] flex items-center justify-between">
          <p>Order History</p>
          <MdOutlineKeyboardArrowRight className="text-3xl text-formisBlack/50" />
        </li>
        <li className="py-[5px] flex items-center justify-between">
          <p>Profile</p>
          <MdOutlineKeyboardArrowRight className="text-3xl text-formisBlack/50" />
        </li>
        <li className="py-[5px] flex items-center justify-between">
          <p>Contact</p>
        </li>
        <li className="py-[5px] flex items-center justify-between">
          <p>Buy</p>
        </li>
        <li className="py-[5px] flex items-center justify-between">
          <p>Blogs</p>
        </li>
      </ul>
    </div>
  );
};

export default OrderHistory;
