import { HiArrowLongLeft } from "react-icons/hi2";
import { IoCloseOutline, IoPaperPlaneSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSearchComponent } from "../../features/searchSlice";
// import { LuShoppingCart } from "react-icons/lu";
import { TiArrowSortedDown } from "react-icons/ti";
import { useEffect, useState } from "react";
import { etaApi } from "../../api";
// import { IoIosSearch } from "react-icons/io";

const SearchTopbar = ({ handlePageChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const items = useSelector((state) => state.cartItems.items);
  const coordinates = useSelector(
    (state) => state.googleMapLocation.coordinates
  );
  const { address, addressType } = useSelector(
    (state) => state.googleMapLocation
  );

  const [eta, setEta] = useState();

  const fetchETA = async () => {
    try {
      const response = await fetch(
        etaApi +
          `latitude=${coordinates.lat || 28.48218399}&longitude=${
            coordinates.lng || 77.09330629
          }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEta(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchETA();
  }, [coordinates]);

  const handleBackButton = () => {
    const flag = new URLSearchParams(location.search).get("search");
    if (flag) navigate(-1);
    else dispatch(toggleSearchComponent());
  };

  return (
    <div className="rounded-t-[20px] sticky -top-2 md2:-top-10 left-0 z-[100] bg-white -mt-7">
      <div className="md2:hidden mx-auto w-16 h-1 bg-formisGrey rounded-full mt-4 mb-2"></div>

      <div className="flex justify-between items-center pb-3 md2:pb-4 px-5">
        <div className="pt-3 flex items-center gap-3">
          <div className="hidden md:block p-2 rounded-full bg-formisBlack">
            <IoPaperPlaneSharp className="text-xl text-formisPeach" />
          </div>
          <div className="font-[Hind]">
            <p className="flex flex-col leading-none text-xs font-semibold">
              Delivery in{" "}
              <span className="text-2xl md:text-3xl font-bold">
                {eta && eta.eta_minutes} minutes
              </span>
            </p>

            <div
              onClick={() => handlePageChange("location")}
              className="cursor-pointer"
            >
              {address && addressType ? (
                <p className="flex items-center leading-none text-sm">
                  <span className="font-bold">{addressType}</span> -{" "}
                  {address.length > 15 ? address.slice(0, 15) + "..." : address}
                  <TiArrowSortedDown className="ml-2" />
                </p>
              ) : (
                <p className="flex items-center leading-none text-xs md:text-sm">
                  Select Address
                  <TiArrowSortedDown className="ml-2" />
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div
            onClick={handleBackButton}
            className={`p-1 md:p-2 md2:p-3 rounded-full border border-formisGrey text-formisBlack xl:hover:text-formisPeach text-xl md:text-2xl cursor-pointer xl:hover:bg-formisBlack transition-all duration-500`}
          >
            <HiArrowLongLeft className="" />
          </div>

          <div
            onClick={() => dispatch(toggleSearchComponent())}
            className="p-1 md:p-2 md2:p-3 rounded-full border border-formisGrey text-formisBlack xl:hover:text-formisPeach text-xl md:text-2xl cursor-pointer xl:hover:bg-formisBlack transition-all hover:rotate-90 duration-500"
          >
            <IoCloseOutline className="" />
          </div>
        </div>
      </div>

      <hr className="border-t border-formisGrey" />
    </div>
  );
};

export default SearchTopbar;
