import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",

  initialState: {
    isSearchOpen: false,
    whichProduct: "",
    whichCategory: ""
  },

  reducers: {
    toggleSearchComponent: (state) => {
      state.isSearchOpen = !state.isSearchOpen;
    },
    handleWhichProduct: (state, action) => {
      state.whichProduct = action.payload;
    },
    handleWhichCategory: (state, action) => {
      state.whichCategory = action.payload;
    },
  },
});

export const { toggleSearchComponent, handleWhichProduct, handleWhichCategory } = searchSlice.actions;
export default searchSlice.reducer;
