import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Footer from "../components/Footer";
import { HiArrowLongRight } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import { categories } from "../categoriesData";
import { useEffect, useState } from "react";
import he from "../assets/he.png"

const Categories = () => {
  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = useState();

  const fetchCategories = async () => {
    try {
      const response = await fetch(`https://kitsa.co.in/api/v1/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setCategoriesData(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>

      <div className="bg-formisWhite px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pt-14 pb-24">
        <h1 className="title-xl font-[800] pb-8 lg:pb-10">Categories</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 md2:grid-cols-3 xl:grid-cols-4 bundle-card-gap">
          <div
            style={{
              backgroundImage: `url(${he})`,
            }}
            className="cursor-pointer relative min-h-[35vh] bg-cover bg-no-repeat rounded-[20px] group"
            onClick={() => navigate("/all-products")}
          >
            <div className="absolute top-0 left-0 bg-formisBlack/40 h-full w-full rounded-[20px]"></div>

            <div className="text-formisPeach relative z-10 px-5 pb-6 2xl:px-10 xl:pb-7 h-full flex items-end">
              <div className="flex justify-between w-full items-center">
                <div>
                  <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                    All Products
                  </h2>
                  <p className="text-[12px]">Check out all our products</p>
                </div>
                <div>
                  <HiArrowLongRight className="text-xl lg:text-2xl" />
                </div>
              </div>
            </div>
          </div>

          {categoriesData && categoriesData.map((category, index) => (
            <Link
              to={`/categories/${category.name}`}
              key={index}
              className="cursor-pointer rounded-[20px]"
            >
              <div className="h-full flex flex-col items-end text-formisBlack bg-white rounded-[20px]">
                <img
                  src={category.image}
                  className="rounded-t-[20px]"
                  alt={category.name}
                />
                <div className="flex justify-between w-full items-center px-5 pb-6 2xl:px-10 xl:pb-7 bg-white rounded-b-[20px]">
                  <div>
                    <h2 className="text-xl lg:text-2xl 2xl:text-3xl max-w-[60%] font-bold mb-1">
                      {category.name}
                    </h2>
                    <p className="text-[12px]">{category.info}</p>
                  </div>
                  <div>
                    <HiArrowLongRight className="text-xl lg:text-2xl" />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <Services />
      <Footer />
    </div>
  );
};

export default Categories;
