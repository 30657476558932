import { useEffect, useRef, useState } from "react";
import useRefresh from "../../hooks/useRefresh";
import { FaUserEdit } from "react-icons/fa";
import { profileApi } from "../../api";

const ProfilePage = ({ handlePageChange }) => {
  const [profileData, setProfileData] = useState();
  const { refreshAccessToken } = useRefresh(handlePageChange);
  const isEffectCalled = useRef(false);

  const [isEditing, setIsEditing] = useState(false);

  const fetchProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(profileApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: null,
      });
      const data = await response.json();
      if (response.ok) {
        setProfileData(data);
        // console.log("profile", data);
      } else if (response.status === 401) {
        // const success = await refreshAccessToken();
        // if (success) {
        //   fetchProfile();
        // }
        localStorage.setItem("isAuthenticated", false)
      }
    } catch (error) {
        console.log(error);
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const saveProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch("https://kitsa.co.in/api/v1/profile", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          first_name: profileData.first_name,
          last_name: profileData.last_name,
        }),
      });
      if (response.ok) {
        setIsEditing(false);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    if (isEffectCalled.current === false) {
      fetchProfile();
    }
    return () => (isEffectCalled.current = true);
  }, []);

  return (
    <div className="px-5 pt-5">
      <div className="flex items-center justify-between gap-3 mb-5">
        <p className="text-lg font-semibold">Profile</p>
        <div className="flex items-center gap-2">
          <FaUserEdit />
          <button onClick={handleEditToggle}>
            {isEditing ? "Cancel" : "Edit"}
          </button>
        </div>
      </div>
      {profileData && (
        <div>
          <div>
            <p>First Name</p>
            <input
              className="address-input"
              readOnly={!isEditing}
              name="first_name"
              value={profileData.first_name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <p>Last Name</p>
            <input
              className="address-input"
              readOnly={!isEditing}
              name="last_name"
              value={profileData.last_name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <p>Mobile Number</p>
            <input
              className="address-input"
              readOnly
              value={profileData.mobile_number}
            />
          </div>
          {isEditing && (
            <button
              onClick={saveProfile}
              className="mt-4 px-4 py-2 bg-formisBlack text-formisPeach rounded-full"
            >
              Save
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
