import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressDetails } from "../features/mapSlice";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";

const UserAddress = ({ handlePageChange }) => {
  const { address, city, state, pinCode } = useSelector(
    (state) => state.googleMapLocation
  );
  const dispatch = useDispatch();

  const [address1, setAddress1] = useState(address);
  const [address2, setAddress2] = useState("");
  const [cityInput, setCityInput] = useState(city);
  const [stateInput, setStateInput] = useState(state);
  const [pinInput, setPinInput] = useState(pinCode);
  const [addressTypeInput, setAddressTypeInput] = useState("");

  const [isSelected, setIsSelected] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setAddressDetails({
        address: address1,
        address2,
        city: cityInput,
        state: stateInput,
        pinCode: pinInput,
        addressType: addressTypeInput,
      })
    );

    handlePageChange("");
  };

  const handleAddressTypeClick = (type, num) => {
    setIsSelected(num);
    setAddressTypeInput(type);
  };

  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>
      <div className="py-5 px-[1.85rem] md:px-10 md2:px-14 lg:px-10 xl:px-12 h-full">
        <div className="flex items-center gap-3 mb-5">
          <p className="title-sm font-semibold">Add Address</p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 items-center justify-center w-full"
        >
          <input
            type="text"
            placeholder="Address Line 1"
            value={address1}
            required
            onChange={(e) => setAddress1(e.target.value)}
            className="address-input"
          />
          <input
            type="text"
            placeholder="Address Line 2"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            className="address-input"
          />
          <input
            type="text"
            placeholder="City"
            value={cityInput}
            required
            onChange={(e) => setCityInput(e.target.value)}
            className="address-input"
          />
          <input
            type="text"
            placeholder="State"
            value={stateInput}
            required
            onChange={(e) => setStateInput(e.target.value)}
            className="address-input"
          />
          <input
            type="number"
            placeholder="Pin Code"
            value={pinInput}
            required
            onChange={(e) => setPinInput(e.target.value)}
            className="address-input"
          />

          <div className="flex items-center justify-start gap-4 w-full">
            <div
              onClick={() => handleAddressTypeClick("Work", 1)}
              className={`${
                isSelected === 1
                  ? "bg-formisBlack text-formisPeach"
                  : "bg-transparent text-formisBlack"
              } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
            >
              Work
            </div>
            <div
              onClick={() => handleAddressTypeClick("Home", 2)}
              className={`${
                isSelected === 2
                  ? "bg-formisBlack text-formisPeach"
                  : "bg-transparent text-formisBlack"
              } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
            >
              Home
            </div>
            <div
              onClick={() => handleAddressTypeClick("Other", 3)}
              className={`${
                isSelected === 3
                  ? "bg-formisBlack text-formisPeach"
                  : "bg-transparent text-formisBlack"
              } border border-formisBlack px-4 py-2 rounded-full transition-colors duration-300 cursor-pointer`}
            >
              Other
            </div>
          </div>

          <div className="flex items-center justify-center w-full py-5">
            <button className="bg-formisBlack text-formisPeach px-10 py-3 rounded-full">
              Confirm Address
            </button>
          </div>
        </form>
      </div>

      <Services />
      <Footer />
    </div>
  );
};

export default UserAddress;
