import { IoCloseOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import NothingInCart from "./Search Components/Search Cart Components/NothingInCart";
import CartFooter from "./Search Components/Search Cart Components/CartFooter";
import CartProduct from "./Search Components/Search Cart Components/CartProduct";

const Cart = ({ isCartOpen, toggleCart }) => {
  const { items } = useSelector((state) => state.cartItems);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isCartOpen ? "bg-formisBlack/30 z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={toggleCart}
      />
      <div
        className={`fixed md2:top-0 md2:right-0 bg-formisWhite z-[31] rounded-t-[20px] md2:rounded-t-none  md2:rounded-tl-[30px] md2:rounded-l-[30px] w-screen h-[91svh] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:pt-10 md2:h-screen
                   transition-transform duration-500 ${
                     isCartOpen
                       ? "slide-up md2:translate-x-0"
                       : "slide-down md2:translate-x-full"
                   }`}
      >
        <div className="">
          <div className="md2:hidden mx-auto w-16 h-1 bg-formisGrey rounded-full my-4"></div>
          <div className="flex justify-between items-center pb-7 md2:pb-10 px-5 md2:px-12">
            <h3 className="text-[26px] md2:text-3xl font-bold">Cart</h3>
            <div
              onClick={toggleCart}
              className="p-2 md2:p-3 rounded-full border border-formisGrey text-formisBlack hover:text-formisPeach text-2xl cursor-pointer hover:bg-formisBlack transition-all hover:rotate-90 duration-500"
            >
              <IoCloseOutline className="" />
            </div>
          </div>

          <hr className="border-t border-formisGrey" />
        </div>

        {items.length > 0 ? (
          <div className="pt-5 flex flex-col justify-between">
            <div className="overflow-scroll h-[14rem] md:h-[27rem]">
              {items.map((item, index) => (
                <CartProduct item={item} key={index} />
              ))}
            </div>
            <div className="fixed bottom-0 right-0 w-full">
              <CartFooter />
            </div>
          </div>
        ) : (
          <NothingInCart />
        )}
      </div>
    </div>
  );
};

export default Cart;
