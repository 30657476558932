import { HiArrowLongRight } from "react-icons/hi2";
import he from "../../assets/he.png";
const CollectionsMenu = () => {
  const images = [he, he, he, he];
  return (
    <div className="px-12 pt-10 pb-14 grid grid-cols-5 gap-5">
      <div className="flex col-span-4 justify-between gap-5">
        {images.map((image, index) => (
          <div key={index} className="relative bg-[35%] rounded-[20px]">
            <div className="relative z-10 h-full flex flex-col items-end text-formisBlack bg-[#fafafa] rounded-[20px]">
              <img
                src={image}
                className="h-[80%] rounded-t-[20px]"
                alt={index}
              />
              <div className="h-[20%] flex justify-between w-full items-center px-5 pb-6 2xl:px-10 xl:pb-7 bg-[#fafafa] rounded-b-[20px]">
                <div>
                  <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                    All Products
                  </h2>
                  <p className="text-[12px]">Check out all our products</p>
                </div>
                <div>
                  <HiArrowLongRight className="text-xl lg:text-2xl" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          backgroundImage: `url(${he})`,
        }}
        className="relative bg-cover bg-no-repeat rounded-[20px] group"
      >
        <div className="absolute top-0 left-0 bg-formisBlack/40 h-full w-full rounded-[20px]"></div>

        <div className="text-formisPeach relative z-10 px-5 pb-6 2xl:px-10 xl:pb-7 h-full flex items-end">
          <div className="flex justify-between w-full items-center">
            <div>
              <h2 className="text-xl lg:text-2xl 2xl:text-3xl font-bold mb-1">
                All Products
              </h2>
              <p className="text-[12px]">Check out all our products</p>
            </div>
            <div>
              <HiArrowLongRight className="text-xl lg:text-2xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsMenu;
