import Advertisements from "../components/Main Page Components/Advertisements";
// import Bundle from "../components/Main Page Components/Bundle";
import ComparisonSlider from "../components/Main Page Components/ComparisonSlider";
import Countdown from "../components/Main Page Components/Countdown";
import FAQ from "../components/FAQ/FAQ";
import Floater from "../components/Floater";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Products from "../components/Main Page Components/Products";
import ProductVideo from "../components/Main Page Components/ProductVideo";
import Services from "../components/Services";
import Topbar from "../components/Topbar";

const Main = () => {
  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>
      <Advertisements />
      <Products />
      {/* <Bundle /> */}
      {/* <ProductVideo /> */}
      <ComparisonSlider />
      {/* <Countdown /> */}
      <Services />
      <FAQ />
      <Footer />

      <Floater />
    </div>
  );
};

export default Main;
