import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import { PiArrowArcLeftLight, PiHeadphonesLight } from "react-icons/pi";
import { GoArrowRight } from "react-icons/go";
import { updateQuantity, removeFromCart } from "../features/cartSlice";
import { CiLock } from "react-icons/ci";
import Footer from "../components/Footer";
import Services from "../components/Services";

const CheckoutCart = () => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.cartItems);
  return (
    <div>
      <Topbar />
      <div className="bg-formisBlack">
        <Header />
      </div>

      <div className="relative bg-formisWhite pt-14 lg:pb-10">
        {items.length > 0 && (
          <div className="md2:flex items-center justify-between mb-8 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12">
            <h1 className="title-xl font-[800] mb-4">Your cart</h1>
            <button className="px-4 py-3 rounded-full border-2 border-formisBlack flex items-center gap-2 font-[500]">
              <PiArrowArcLeftLight className="text-xl md2:text-2xl" /> <span className="button-font">Continue shopping</span>
            </button>
          </div>
        )}

        <div className={`bg-formisWhite`}>
          {items.length > 0 ? (
            <div className="flex flex-col gap-8 lg:flex-row justify-between">
              <div className="lg:w-[65%] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12">
                {items.map((item, index) => (
                  <div key={index} className="flex gap-3 md2:gap-10 items-center mb-4">
                    <div className="border-2 size-20 md2:size-32 aspect-square rounded-[5px]" />
                    <div className="flex w-full items-center justify-between">
                      <div>
                        <p className="font-[600] md2:text-lg">{item.name}</p>
                        <span className="text-xs md2:text-sm">{item.tag}</span>
                        <p className="text-sm md2:text-base md2:font-[500]">Rs. {item.price}</p>
                      </div>
                      <div className="flex flex-col items-center">
                        <input
                          type="number"
                          min="1"
                          value={item.quantity}
                          className="max-w-12 bg-formisBlack/10 rounded-[5px] p-1 mb-3 text-center outline-none"
                          onChange={(e) =>
                            dispatch(
                              updateQuantity({
                                id: item.id,
                                quantity: Number(e.target.value),
                              })
                            )
                          }
                        />
                        <button
                          onClick={() =>
                            dispatch(removeFromCart({ id: item.id }))
                          }
                          className="text-xs border-b border-formisBlack/50"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="lg:w-[35%] lg:rounded-[10px] px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 lg:mr-9 xl:mr-12 py-6 pb-24 w-screen bg-formisGrey/50">
                <div className="flex justify-between mb-3 border-b border-formisBlack/30 pb-3">
                  <p className="font-medium md2:text-2xl">Subtotal</p>
                  <h3 className="text-2xl md:text-3xl font-bold leading-none tracking-tight">
                    Rs. 20,700.00
                  </h3>
                </div>
                <div className="mb-7 text-sm tracking-wide">
                  <p>Taxes included and shipping calculated at checkout.</p>
                </div>
                <div className="mb-7">
                  <p className="mb-3 text-lg font-semibold">
                    Add a note to your order
                  </p>
                  <textarea
                    placeholder="Order Note"
                    className="bg-transparent border text-sm border-formisBlack/30 w-full rounded-[10px] outline-none p-6"
                  />
                </div>
                <div className="flex justify-end w-full">
                  <button className="flex items-center justify-center gap-2 font-[500] bg-formisBlack text-formisPeach py-4 rounded-full w-full">
                    <CiLock className="text-2xl" /> Check out
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center gap-5 md:gap-8 max-w-[65%] md2:max-w-[55%] mx-auto md2:h-[80vh] pb-24">
              <h3 className="text-2xl md2:text-3xl font-bold text-center leading-none tracking-tight">
                Your cart is <br className="hidden 2xl:block" /> currently{" "}
                <br className="2xl:hidden" /> empty.
              </h3>
              <p className="leading-tight text-sm md2:text-base text-center">
                Not sure where to start? <br /> Try these collections:
              </p>
              <ul className="w-full flex flex-col gap-3">
                <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
                  <span className="flex gap-3 items-center">
                    <PiHeadphonesLight className="text-3xl" />
                    Headphones
                  </span>
                  <GoArrowRight className="text-2xl" />
                </li>
                <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
                  <span className="flex gap-3 items-center">
                    <PiHeadphonesLight className="text-3xl" />
                    Headphones
                  </span>
                  <GoArrowRight className="text-2xl" />
                </li>
                <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
                  <span className="flex gap-3 items-center">
                    <PiHeadphonesLight className="text-3xl" />
                    Headphones
                  </span>
                  <GoArrowRight className="text-2xl" />
                </li>
                <li className="flex items-center justify-between w-full bg-formisGrey/50 px-5 py-3 rounded-[10px]">
                  <span className="flex gap-3 items-center">
                    <PiHeadphonesLight className="text-3xl" />
                    Headphones
                  </span>
                  <GoArrowRight className="text-2xl" />
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <Services />
      <Footer />
    </div>
  );
};

export default CheckoutCart;
