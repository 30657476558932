import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",

  initialState: {
    isAuthenticated: null,
  },

  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
  },
});

export const { setIsAuthenticated } = authSlice.actions;
export default authSlice.reducer;
