import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",

  initialState: {
    isProfileOpen: false,
  },

  reducers: {
    toggleProfileComponent: (state) => {
      state.isProfileOpen = !state.isProfileOpen;
    },
  },
});

export const { toggleProfileComponent } = profileSlice.actions;
export default profileSlice.reducer;
